import axios from "axios";
import store from "../vuex/store";
import * as config from "../config";

const instance = axios.create({
  baseURL: config.api.baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  },
});

const sessions = {
  logout() {
    return instance.post("v1/sessions/logout").then(function (response) {
      return response.data;
    });
  },

  getInfo() {
    return instance.get("v1/sessions").then(function (response) {
      return response.data;
    });
  },

  terminateById(id) {
    return instance
      .post(`v1/sessions/${id}/terminate`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error terminating session:", error);
        throw error;
      });
  },

  terminateAll() {
    return instance
      .post(`v1/sessions/terminate`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error terminating all sessions:", error);
        throw error;
      });
  },

  settingsGetInfo() {
    return instance.get("v1/sessions/settings").then(function (response) {
      return response.data;
    });
  },

  settingsUpdateExpiration(value) {
    var params = new URLSearchParams();
    params.append("value", value);
    return instance
      .post("v1/sessions/settings/expiration", params)
      .then(function (response) {
        return response.data;
      })
      .catch((error) => {
        console.error("Error updating session expiration:", error);
        throw error;
      });
  },
};

const account = {
  login(creditionals) {
    var params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("client_id", config.api.client_id);
    params.append("client_secret", config.api.client_secret);
    params.append("password", creditionals[1]);
    params.append("username", creditionals[0]);

    console.log(params);

    return instance.post("oauth/token", params).then(function (response) {
      console.log(response);
      return response.data;
    });
  },

  changePassword(creditionals) {
    var params = new URLSearchParams();
    params.append("old_password", creditionals[0]);
    params.append("password", creditionals[1]);
    params.append("sessions_terminate_others", creditionals[2]);

    console.log(params);

    return instance
      .post("v1/account/password", params)
      .then(function (response) {
        console.log(response);
        return response.data;
      });
  },

  refreshToken(refreshToken) {
    var params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("client_id", config.api.client_id);
    params.append("client_secret", config.api.client_secret);
    params.append("refresh_token", refreshToken);

    return instance.post("oauth/token", params).then(function (response) {
      return response.data;
    });
  },

  getInfo(includes) {
    var baseUrl = "v1/account";

    if (includes) {
      baseUrl += "?include=" + includes.join();
    }

    return instance.get(baseUrl).then(function (response) {
      return response.data.data;
    });
  },

  getAccountStats(year = null) {
    return instance
      .get("v1/account/stats/year/" + year)
      .then(function (response) {
        return response.data;
      });
  },

  getInternetSettings() {
    return instance
      .get("v1/account/internet/settings")
      .then(function (response) {
        return response.data;
      });
  },

  changeInternetSettings(url) {
    return instance.post(url).then(function (response) {
      return response.data;
    });
  },

  getTariffs() {
    return instance.get("v1/account/tariffs").then(function (response) {
      console.log(response);
      return response.data;
    });
  },

  getBadges() {
    return instance.get("v1/account/badges").then(function (response) {
      return response.data.data;
    });
  },

  getServices() {
    return instance.get("v1/account/services").then(function (response) {
      return response.data.data;
    });
  },

  getLatestPlusPayment() {
    return instance
      .get("v1/account/payments/latest/plus")
      .then(function (response) {
        return response.data.data;
      });
  },

  getNetwork() {
    return instance.get("v1/account/network").then(function (response) {
      return response.data.data;
    });
  },

  getCurrentTariff() {
    return instance.get("v1/account/tariffs/current").then(function (response) {
      return response.data.data;
    });
  },

  getAvailableTariffs() {
    return instance
      .get("v1/account/tariffs/available")
      .then(function (response) {
        return response.data.data;
      });
  },

  setPlannedTariff(name) {
    var params = new FormData();
    params.append("name", name);
    return instance
      .post("v1/account/tariffs/planned", params)
      .then(function (response) {
        return response.data;
      });
  },

  getPlannedTariff() {
    return instance.get("v1/account/tariffs/planned").then(function (response) {
      return response.data;
    });
  },

  deletePlannedTariff() {
    return instance
      .delete("v1/account/tariffs/planned")
      .then(function (response) {
        console.log(response);
        return response.data;
      });
  },

  getPayments(page) {
    if (page && page !== 0) {
      return instance
        .get("v1/account/payments?page=" + page)
        .then(function (response) {
          return response.data;
        });
    } else {
      return instance.get("v1/account/payments").then(function (response) {
        return response.data;
      });
    }
  },

  getPaymentMessages() {
    return instance
      .get("v1/account/payments/messages")
      .then(function (response) {
        return response.data.data;
      });
  },

  getRecommendedPaymentAmount() {
    return instance
      .get("v1/account/payments/recommended")
      .then(function (response) {
        return response.data.data;
      });
  },

  getPaymentLink(method, amount) {
    return instance
      .get("v1/account/payments/link/" + method + "/?amount=" + amount)
      .then(function (response) {
        return response.data.data;
      });
  },

  createOrder() {
    return instance.post("v1/account/orders").then(function (response) {
      return response.data.data;
    });
  },

  createOrderByAccountNumber(accountNumber) {
    var params = new URLSearchParams();
    params.append("account_number", accountNumber);
    return instance.post("v1/orders", params).then(function (response) {
      return response.data.data;
    });
  },

  postCredit() {
    return instance.post("v1/account/credits").then(function (response) {
      console.log(response);
      return response.data;
    });
  },

  postChatroom() {
    return instance.post("v1/account/chatroom").then(function (response) {
      return response.data.data;
    });
  },

  getChatRoomMessages(page) {
    if (page && page !== 0) {
      return instance
        .get("v1/account/chatroom/messages?page=" + page)
        .then(function (response) {
          return response.data;
        });
    } else {
      return instance
        .get("v1/account/chatroom/messages")
        .then(function (response) {
          return response.data;
        });
    }
  },

  postChatroomMessage(message = null, files = null) {
    instance.defaults.headers["Content-Type"] = "multipart/form-data";
    var params = new FormData();

    if (message) {
      params.append("message", message);
    }

    if (files) {
      if (!Array.isArray(files)) {
        params.append("attachments[]", files[0]);
      } else {
        console.log(files);
        files.forEach(function (file) {
          params.append("attachments[]", file);
        });
      }
    }

    return instance
      .post("v1/account/chatroom/messages", params)
      .then(function (response) {
        instance.defaults.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        return response.data;
      });
  },

  getMessages(page, id) {
    let baseUrl = "v1/account/messages";

    if (page && page !== 0) {
      baseUrl += "?page=" + page;
    } else {
      if (id) {
        baseUrl += "/" + id;
      }
    }

    return instance.get(baseUrl).then(function (response) {
      return response.data;
    });
  },

  refreshAccessToken() {
    return instance.get("oauth/token").then(function (response) {
      return response.data.data;
    });
  },
};

const banners = {
  getBanners() {
    return instance.get("v1/banners").then(function (response) {
      return response.data.data;
    });
  },

  getImage(url) {
    return instance.get(url).then(function (response) {
      return response;
    });
  },
};

const pushes = {
  putDeviceId(deviceId) {
    return instance
      .put("v1/account/devices/" + deviceId)
      .then(function (response) {
        return response.data.data;
      });
  },

  deleteDeviceId(deviceId) {
    return instance
      .delete("v1/account/devices/" + deviceId)
      .then(function (response) {
        return response.data.data;
      });
  },
};

const fpst = {
  postFpstSession() {
    return instance.post("v1/fpst/session").then(function (response) {
      return response.data;
    });
  },

  postFpstEmbedClientUrl() {
    return instance.post("v1/fpst/embed-client").then(function (response) {
      return response.data;
    });
  },
};

instance.interceptors.request.use(function (config) {
  const access_token = store.state.access_token;
  if (access_token) {
    config.headers["Authorization"] = "Bearer " + access_token;
  }
  return config;
});

let isRefreshing = false;
const refreshSubscribers = [];

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        console.log("!isRefreshing");
        isRefreshing = true;
        store
          .dispatch("refreshToken")
          .then((data) => {
            isRefreshing = false;
            refreshSubscribers.map((cb) => cb(data.access_token));
          })
          .catch(() => {
            isRefreshing = false;
            store.dispatch("silentExit");
          });
      }

      const retryOrigReq = new Promise((resolve) => {
        refreshSubscribers.push((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(axios(originalRequest));
        });
      });

      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  },
);

export { instance, account, banners, fpst, pushes, sessions };
//, pushes }
