<template>
  <div class="wrapper">
    <template v-if="isLoading">
      <loader-component />
    </template>
    <profile-component v-else-if="isSuccess" :account="account" />
    <error-component
      @refresh="updateAccountInfo(true)"
      v-else-if="!isSuccess"
      :message="message"
    />
  </div>
</template>

<script>
import ProfileComponent from "../common/ProfileComponent.vue";
import { mapState } from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";

export default {
  name: "PageProfile",
  components: {
    ProfileComponent,
    LoaderComponent,
    ErrorComponent,
  },

  computed: {
    ...mapState({
      account: (state) => state.account.accountInfo,
    }),
  },

  data: function () {
    return {
      isSuccess: true,
      isLoading: false,
      message: null,
    };
  },

  methods: {
    fetchAccountInfo() {
      this.isLoading = true;
      this.$store
        .dispatch("account/fetchAccountInfo")
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.isSuccess = false;
          if (error.response) {
            this.message = error;
            //this.$constants.errors.unexpected;
          } else {
            this.message = error;
            //this.$constants.errors.unexpected;
          }
        });
    },

    updateAccountInfo(forced = false) {
      if (forced) {
        this.fetchAccountInfo();
      } else {
        if (!this.account || this.account.account === null) {
          this.fetchAccountInfo();
        }
      }
    },
  },

  mounted() {
    this.updateAccountInfo();
  },
};
</script>
