<template>
  <div id="app">
    <div v-if="isAuthorized" id="wrapper">
      <back-to-top-component />
      <top-component />
      <app-menu />
      <div
        v-if="this.$route.name != 'support' && this.$route.name != 'cams'"
        id="page-wrapper"
      >
        <div class="container">
          <head-component
            v-if="this.windowSizes.width > 768 || this.$route.name === 'home'"
          />
          <div id="router-view-wrapper">
            <router-view />
          </div>
        </div>
        <div id="sidebar_overlay" @click="overlayOnClick" />
      </div>

      <div v-else id="page-wrapper" style="padding-top: 0">
        <div id="router-view-wrapper">
          <router-view />
        </div>
        <div id="sidebar_overlay" @click="overlayOnClick" />
      </div>
    </div>

    <div v-else class="auth-wrapper">
      <auth />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppMenu from "./common/AppMenu";
import Auth from "./pages/Auth";
import HeadComponent from "./common/HeadComponent.vue";
import TopComponent from "./common/TopComponent.vue";
import BackToTopComponent from "./common/BackToTopComponent.vue";
import firebase from "firebase/app";

// import { default as Message } from "./message/main.js";

export default {
  name: "App",

  data: function () {
    return {
      messaging: null,
      push: null,
      pushFCM: null,

      windowSizes: {
        width: 0,
        height: 0,
      },
    };
  },

  watch: {
    $route(to) {
      if (to.name !== "auth") {
        if (this.isAuthorized) {
          this.subscribe();
          this.fetchBadges();
        }
      }
    },
  },

  components: {
    HeadComponent,
    AppMenu,
    Auth,
    TopComponent,
    BackToTopComponent,
  },

  computed: {
    ...mapState(["loading", "isAuthorized"]),

    badgeMessages: {
      get() {
        return this.$store.getters["account/getBadgesMessages"];
      },
      set(value) {
        this.$store.commit("account/SET_BADGES_MESSAGES", value);
      },
    },

    badgeChat: {
      get() {
        return this.$store.getters["account/getBadgesChat"];
      },
      set(value) {
        this.$store.commit("account/SET_BADGES_CHAT", value);
      },
    },
  },

  methods: {
    overlayOnClick() {
      // $("body").toggleClass("enlarged");
    },

    fetchBadges() {
      this.$nextTick(function () {
        console.log("fetchBadges");
        if (this.isAuthorized) {
          this.$store.dispatch("account/fetchBadges");
        }
      });
    },

    subscribe() {
      console.log("subscribe");

      if (!this.messaging) {
        return;
      }

      this.messaging
        .requestPermission()
        .then(() => {
          this.messaging
            .getToken()
            .then((currentToken) => {
              if (!this.isTokenSentToServer(currentToken)) {
                this.$store
                  .dispatch("putDeviceId", currentToken)
                  .then(() => {
                    this.setTokenSentToServer(currentToken);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch(function (err) {
              console.warn("При получении токена произошла ошибка.", err);
            });
        })
        .catch(function (err) {
          console.warn(
            "Не удалось получить разрешение на показ уведомлений.",
            err,
          );
        });
    },

    initFcm() {
      var config = {
        apiKey: "AIzaSyCuU3RGnvuCfg8m6m_bI7vOphlr__kjA2o",
        authDomain: "local-notification-ada04.firebaseapp.com",
        projectId: "local-notification-ada04",
        storageBucket: "local-notification-ada04.appspot.com",
        messagingSenderId: "394372186260",
        appId: "1:394372186260:web:4db646c55c4a346861f481",
        measurementId: "G-191VXVKZHT",
      };

      try {
        firebase.initializeApp(config);
        this.messaging = firebase.messaging();
      } catch (e) {
        //
        console.log(e);
      }

      if (!this.messaging) {
        return;
      }

      var that = this;

      this.messaging.onMessage((payload) => {
        const action = payload.notification.click_action;
        if (action) {
          if (action === "A_CHAT") {
            //console.log("A_CHAT");
            this.badgeChat++;
          } else if (action === "A_MESSAGE") {
            //if (this.$route.name !== "messages") {
            this.badgeMessages++;
            //}
            //if (this.$route.name === "messages") {
            this.$store.dispatch("messages/fetchMessages", 1).then(() => {
              console.log("fetchMessages");
            });
            //}
          } else if (action === "A_PAYMENTS") {
            that.$store.dispatch("account/fetchAccountInfo").then(() => {
              this.$store
                .dispatch("payments/fetchLatestPlusPayment")
                .then(() => {
                  this.$store.dispatch("payments/fetchPayments", 1).then(() => {
                    console.log("fetchPayments");
                  });
                });
            });
          }
        }

        navigator.serviceWorker
          .getRegistration("/firebase-cloud-messaging-push-scope")
          .then((registration) => {
            registration.update();
          });
      });
    },

    isTokenSentToServer(currentToken) {
      if (window.localStorage) {
        return (
          window.localStorage.getItem("sentFirebaseMessagingToken") ===
          currentToken
        );
      }
      return false;
    },

    setTokenSentToServer(currentToken) {
      if (window.localStorage) {
        window.localStorage.setItem(
          "sentFirebaseMessagingToken",
          currentToken || "",
        );
      }
    },

    handleResize() {
      this.windowSizes.width = window.innerWidth;
      this.windowSizes.height = window.innerHeight;
    },
  },

  beforeMount() {},

  mounted() {},

  created: function () {
    this.initFcm();

    console.log("after init fcm");

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (this.$route.name !== "auth") {
      if (this.isAuthorized) {
        this.subscribe();
        this.fetchBadges();
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
</style>
