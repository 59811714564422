<script setup>
import LoaderComponent from "../common/LoaderComponent.vue";
import ButtonElement from "../button/button.vue";

export default {
  name: "DeviceSettings",
  components: {
    LoaderComponent,
    ButtonElement,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    maxNonUsePeriod: {
      type: [Object, Array],
      default: () => ({
        options: [],
        selected: null,
      }),
    },
  },
  methods: {
    save(event) {
      this.$emit("change-session-ttl", event.target.value);
    },
  },
};
</script>

<template>
  <div>
    <template v-if="isLoading">
      <loader-component />
    </template>
    <template v-else>
      <h6>Автозавершение после периода неактивности:</h6>
      <template
        v-if="
          maxNonUsePeriod &&
          maxNonUsePeriod.options &&
          maxNonUsePeriod.options.length
        "
      >
        <div
          style="display: block"
          v-for="item in maxNonUsePeriod.options"
          :key="item.value"
        >
          <input
            :checked="item.value === maxNonUsePeriod.selected"
            name="session_lifetime"
            :id="item.value"
            :value="item.value"
            type="radio"
            @change="save"
          />
          <label :for="item.value" style="margin-left: 8px">{{
            item.text
          }}</label>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped>
input {
  margin: 0.4rem;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;
  align-self: center;
  border-radius: 50%;
}
</style>
