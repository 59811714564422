<script setup>
import DeviceListItem from "./DeviceListItem.vue";

export default {
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
    modal: {
      type: Object,
      default: function () {
        return {
          show: false,
        };
      },
    },
  },

  // devices: [
  //   {
  //     id: Number,
  //     deviceName: String,
  //     appVersion: String,
  //     date: String,
  //     ip: String
  //   }
  // ],
  // modal: {
  //   show: Boolean,
  //   selectedDevice: {
  //     id: Number,
  //     deviceName: String,
  //     appVersion: String,
  //     date: String,
  //     ip: String
  //   },
  // },
  components: {
    DeviceListItem,
    // DeviceListItemModal
  },
  methods: {
    terminateSession: function (id) {
      this.$emit("terminate", id);
    },
  },
};
</script>

<template>
  <div style="max-width: 600px">
    <div v-for="device in devices" :key="device.id">
      <DeviceListItem
        v-if="device"
        :device="device"
        @terminate="terminateSession"
      />
    </div>
  </div>
</template>

<style scoped></style>
