import * as api from "../../api/courier_api.js";
import moment from "moment";
import Vue from "vue";

const chatroom = {
  namespaced: true,

  state: {
    chatRoomMessages: {
      allKeys: [],
      all: [],
      allTotalPages: null,
      allCurrentPage: 0,
      lastMessageId: 0,
    },
  },
  mutations: {
    SET_CHATROOM_CURRENT_PAGE(state, page) {
      state.chatRoomMessages.allCurrentPage = page;
    },

    ADD_CHATROOM_MESSAGES(state, payload) {
      var newMessages = payload.data.data;

      state.chatRoomMessages.allCurrentPage =
        payload.data.meta.pagination.current_page;

      state.chatRoomMessages.allTotalPages =
        payload.data.meta.pagination.total_pages;

      let i = 0;

      newMessages.forEach(function (message) {
        if (!message.read && message.support_id != null) {
          message.read = true;
          i++;
        }

        message.created = moment.unix(message.created);

        message.checked = true;

        message.createdDay = Vue.options.filters.formatDay(message.created);

        if (!state.chatRoomMessages.allKeys.includes(message.createdDay)) {
          console.log("unshift");
          state.chatRoomMessages.allKeys.unshift(message.createdDay);
        }

        console.log(state.chatRoomMessages.allKeys);

        let id = message.id;
        let messageObject = {};

        Vue.set(messageObject, id, message);

        let reactiveObject = Object.assign({}, messageObject);

        state.chatRoomMessages.all[message.createdDay] = Object.assign(
          {},
          state.chatRoomMessages.all[message.createdDay],
          reactiveObject,
        );

        state.chatRoomMessages.lastMessageId = message.id;
      });

      state.chatRoomMessages.all = Object.assign(
        {},
        state.chatRoomMessages.all,
      );
      console.log(state.chatRoomMessages.all);

      if (state.badgesChat - i > 0) {
        state.badgesChat -= i;
      }
    },

    CLEAR_STATE(state) {
      state.chatRoomMessages = {
        allKeys: [],
        all: [],
        allTotalPages: null,
        allCurrentPage: 0,
        lastMessageId: 0,
      };
    },
  },
  actions: {
    fetchChatRoomMessages: ({ commit }, page = 0) => {
      return api.account.getChatRoomMessages(page).then((data) => {
        commit("ADD_CHATROOM_MESSAGES", { data });
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    fetchChatRoomMessagesReturnValues: ({ commit }, page = 0) => {
      return api.account.getChatRoomMessages(page).then((data) => {
        return data;
      });
    },

    /*postChatroom: ({ commit }) => {
      return api.account.postChatroom().then(data => {
        commit("SET_CENTRIFUGE", { data });
        return data;
      });
    },*/
  },
  getters: {
    getChatRoomMessagesAllKeys: (state) => {
      return state.chatRoomMessages.allKeys;
    },

    getChatroomMessageLastId: (state) => {
      return state.chatRoomMessages.lastMessageId;
    },

    allChatroomMessagesTotalPages: (state) => {
      return state.chatRoomMessages.allTotalPages;
    },

    allChatroomMessagesCurrentPage: (state) => {
      return state.chatRoomMessages.allCurrentPage;
    },
    allChatRoomMessages: (state) => {
      return state.chatRoomMessages.all;
    },
  },
};

export default chatroom;
