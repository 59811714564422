<template>
  <button class="goTop" v-if="isVisible" @click="backToTop">
    <i class="fa fa-angle-up" aria-hidden="true" />
  </button>
</template>

<script>
export default {
  data: function () {
    return {
      isVisible: false,
    };
  },
  methods: {
    initToTopButton: function () {
      $(document).bind(
        "scroll",
        function () {
          var backToTopButton = $(".goTop");
          if ($(document).scrollTop() > 250) {
            backToTopButton.addClass("isVisible");
            this.isVisible = true;
          } else {
            backToTopButton.removeClass("isVisible");
            this.isVisible = false;
          }
        }.bind(this),
      );
    },
    backToTop: function () {
      $(document).ready(function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
      });
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.initToTopButton();
    });
  },
};
</script>
