<template>
  <div class="card tariff-card mx-auto">
    <div class="card-header">
      <div class="row">
        <div
          class="col-12 tariff-name"
          style="text-align: center; line-height: 55px"
        >
          <div
            class="circle"
            v-if="
              id === 'EVERYTHINGEASY' ||
              id === 'THATISIT' ||
              id === 'YOUNEVERDREAM' ||
              id === 'GIGABIT1' ||
              id === 'GIGABIT2'
            "
          >
            <img
              v-if="id === 'EVERYTHINGEASY'"
              :src="require('@/assets/images/tariffs_everythingeasy.png')"
            />
            <img
              v-else-if="id === 'THATISIT'"
              :src="require('@/assets/images/tariffs_thatisit.png')"
            />
            <img
              v-else-if="id === 'YOUNEVERDREAM'"
              :src="require('@/assets/images/tariffs_youneverdream.png')"
            />
            <img
              v-if="id === 'GIGABIT1'"
              :src="require('@/assets/images/tariffs_vortex.png')"
            />
            <img
              v-else-if="id === 'GIGABIT2'"
              :src="require('@/assets/images/tariffs_typhoon.png')"
            />
          </div>
          {{ name }}
          <p
            style="line-height: normal; text-transform: none"
            v-if="plannedDate"
          >
            с {{ plannedDate | formatDay }}
          </p>
        </div>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="title">
          {{ $constants.tariffs.speed_day }}
        </div>
        <div class="text">
          до {{ speedDay }}
          <span class="text-small" v-if="speedUnit">
            {{ speedUnit }}
          </span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="title">
          {{ $constants.tariffs.speed_night }}
        </div>
        <div class="text">
          до {{ speedNight }}
          <span class="text-small">
            {{ speedUnit }}
          </span>
        </div>
      </li>
      <li
        v-if="!creditDisabled && creditExpired !== null"
        class="list-group-item"
      >
        <div class="title">
          {{ $constants.tariffs.promised }}
        </div>
        <div class="text">
          На {{ creditExpired }}
          {{ getNoun(creditExpired, "день", "дня", "дней") }}
        </div>
      </li>
      <li v-if="bonusText" class="list-group-item">
        <img
          height="55px;"
          width="55px;"
          :src="require('@/assets/images/payments_gift.png')"
        />
        <div class="text">
          {{ bonusText }}
        </div>
      </li>
      <li class="list-group-item">
        <div class="price-wrapper">
          <div class="price">
            {{ price }} <i class="fa fa-rub" aria-hidden="true" />
          </div>
        </div>
      </li>
      <!--<li
        class="list-group-item"
        v-if="plannedDate">
        <div class="title">
          Дата перехода
        </div>
        <div class="text">
          {{ plannedDate | formatDay }}
        </div>
      </li>-->
    </ul>
    <div class="card-footer" v-if="isButtonNeeded">
      <button-element round @click="handleClick">
        {{ showChangeTariffButton ? "Перейти" : "Отменить" }}
      </button-element>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../button/button";

export default {
  name: "TariffCard",
  components: {
    ButtonElement,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    speedUnit: {
      type: String,
      default: null,
    },
    speedDay: {
      type: Number,
      default: null,
    },
    speedNight: {
      type: Number,
      default: null,
    },
    bonusText: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default: null,
    },
    metaCanCancel: {
      type: Boolean,
      default: null,
    },
    plannedDate: {
      type: Object,
      default: null,
    },
    showChangeTariffButton: {
      type: String,
      default: null,
    },
    showCancelTariffButton: {
      type: Boolean,
      default: false,
    },
    creditDisabled: {
      type: Boolean,
      default: null,
    },
    creditExpired: {
      type: Number,
      default: null,
    },
  },

  computed: {
    canCancel() {
      return this.metaCanCancel ? this.metaCanCancel : false;
    },
    /*plannedDate() {
      return this.meta ? this.meta.planned : null
    },*/
    isButtonNeeded() {
      return (
        this.showChangeTariffButton ||
        (this.showCancelTariffButton && this.canCancel)
      );
    },
  },

  methods: {
    handleClick() {
      if (this.showChangeTariffButton) {
        this.$emit("changeTariff", this.id);
      } else if (this.showCancelTariffButton) {
        this.$emit("cancelTariff", this.id);
      }
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
  },
};
</script>

<style lang="scss">
@import "./card.sass";
</style>
