<template>
  <div class="wrapper">
    <div class="white-box">
      <div class="white-box_header">Профиль</div>

      <div class="white-box_body">
        <nav>
          <div class="nav nav-tabs" id="profile-tabs" role="tablist">
            <a
              class="nav-item nav-link active"
              id="profile-info-tab"
              data-toggle="tab"
              href="#profile-info"
              role="tab"
              aria-controls="profile-info"
              aria-selected="true"
            >
              Информация
            </a>
            <a
              class="nav-item nav-link"
              id="profile-settings-tab"
              data-toggle="tab"
              href="#profile-settings"
              role="tab"
              aria-controls="profile-settings"
              aria-selected="false"
            >
              Настройки
            </a>
          </div>
        </nav>

        <div class="tab-content" id="profileTabs-tabContent">
          <div
            class="tab-pane fade show active"
            id="profile-info"
            role="tabpanel"
            aria-labelledby="profile-info-tab"
          >
            <div class="profile">
              <div class="row">
                <div class="col-12 col-md-6">
                  {{ $constants.accountInfo.full_name }}
                </div>
                <div class="col-12 col-md-6">
                  {{ account.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  {{ $constants.accountInfo.login }}
                </div>
                <div class="col-12 col-md-6">
                  {{ account.login }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  {{ $constants.accountInfo.account }}
                </div>
                <div class="col-12 col-md-6">
                  {{ account.account }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  {{ $constants.accountInfo.email }}
                </div>
                <div class="col-12 col-md-6" v-if="account.email">
                  <a
                    href="https://shgs.ru/mail.html"
                    class="link"
                    target="_blank"
                    >{{ account.email }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--          <div-->
          <!--              class="tab-pane fade"-->
          <!--              id="active-sessions"-->
          <!--              role="tabpanel"-->
          <!--              aria-labelledby="active-sessions-tab">-->
          <!--            <div class="sessions" style="margin-top: 1rem">-->
          <!--              <DevicesPage />-->
          <!--            </div>-->
          <!--          </div>-->
          <div
            class="tab-pane fade"
            id="profile-settings"
            role="tabpanel"
            aria-labelledby="profile-settings-tab"
          >
            <div class="settings">
              <h6>Изменение пароля аккаунта</h6>
              <div class="alert alert-danger" role="alert" v-if="account.email">
                Внимание! Пароль от почты (<a
                  href="https://shgs.ru/mail.html"
                  class="link"
                  target="_blank"
                  >{{ account.email }}</a
                >) будет изменен на аналогичный
              </div>
              <div class="form-group">
                <label for="old-password" class="control-label"
                  >Старый пароль</label
                >
                <input
                  autocomplete="off"
                  id="old-password"
                  class="form-control ym-disable-keys"
                  type="password"
                  placeholder="Старый пароль"
                  v-model="changePasswordValues.oldPassword"
                  name="old-password"
                  @keyup.enter="changePassword()"
                />
              </div>

              <div class="form-group">
                <label for="new-password" class="control-label"
                  >Новый пароль</label
                >
                <input
                  autocomplete="off"
                  id="new-password"
                  class="form-control ym-disable-keys"
                  type="password"
                  placeholder="Новый пароль"
                  v-model="changePasswordValues.newPassword"
                  name="new-password"
                  @keyup.enter="changePassword()"
                />
              </div>

              <div class="form-group">
                <label for="repeat-new-password" class="control-label"
                  >Повторите пароль</label
                >
                <input
                  autocomplete="off"
                  id="repeat-new-password"
                  class="form-control ym-disable-keys"
                  type="password"
                  placeholder="Повторите пароль"
                  v-model="changePasswordValues.newPasswordRepeat"
                  name="repeat-new-password"
                  @keyup.enter="changePassword()"
                />
              </div>

              <div class="form-group">
                <div>
                  <input
                    autocomplete="off"
                    id="terminate-others-sessions"
                    style="margin-right: 6px"
                    type="checkbox"
                    placeholder="Завершить все другие сеансы"
                    name="terminate-others-sessions"
                    v-model="changePasswordValues.terminateOthersSessions"
                    @change="
                      changePasswordValues.terminateOthersSessions === 0 ? 1 : 0
                    "
                  />

                  <label
                    for="terminate-others-sessions"
                    class="form-check-label"
                    >Завершить все другие сеансы</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button-element round @click="changePassword">
                    Изменить пароль
                  </button-element>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-window
      v-if="showModal"
      :closable="closable"
      :show="showModal"
      :header-text="header"
      :text="message"
      :buttons="buttons"
      @closeModalForm="closeModalForm"
      @close="closeModalForm"
    />
  </div>
</template>

<script>
import ButtonElement from "../button/button";
import ModalWindow from "../modal/main";
import DevicesPage from "../pages/Devices.vue";

export default {
  name: "ProfileComponent",
  data() {
    return {
      showModal: false,
      header: null,
      message: null,
      closable: false,
      buttons: null,

      controlButtons: [
        {
          text: "Отменить",
          emitEvent: "closeModalForm",
        },
        {
          text: "Продолжить",
          emitEvent: "changePassword",
        },
      ],

      changePasswordValues: {
        oldPassword: null,
        newPassword: null,
        newPasswordRepeat: null,
        terminateOthersSessions: 0,
      },
    };
  },
  components: {
    DevicesPage,
    ModalWindow,
    ButtonElement,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changePassword() {
      if (!this.showModal) {
        if (
          !this.changePasswordValues.oldPassword ||
          this.changePasswordValues.oldPassword.length === 0 ||
          !this.changePasswordValues.newPassword ||
          this.changePasswordValues.newPassword.length === 0 ||
          !this.changePasswordValues.newPasswordRepeat ||
          this.changePasswordValues.newPasswordRepeat.length === 0
        ) {
          this.header = "Ошибка";
          this.message = "Необходимо заполнить все поля";
          this.showModal = true;
          this.closable = true;
        } else {
          if (
            this.changePasswordValues.newPassword ===
            this.changePasswordValues.newPasswordRepeat
          ) {
            this.message = "";
            this.showModal = true;
            this.$store
              .dispatch("changePassword", [
                this.changePasswordValues.oldPassword,
                this.changePasswordValues.newPassword,
                this.changePasswordValues.terminateOthersSessions,
              ])
              .then(() => {
                this.header = "Успех";
                this.message = "Пароль успешно изменен";
                this.closable = true;
                this.changePasswordValues.oldPassword = null;
                this.changePasswordValues.newPassword = null;
                this.changePasswordValues.newPasswordRepeat = null;
              })
              .catch((error) => {
                console.log(error);
                this.header = "Ошибка";
                this.closable = true;
                if (error.response) {
                  if (error.response.status == 400) {
                    this.message = error.response.data.message;
                    this.showModal = true;
                  } else if (error.response.status == 500) {
                    this.message = this.$constants.errors.server_unavailable;
                    this.showModal = true;
                  } else {
                    this.message = this.$constants.errors.server_unavailable;
                    this.showModal = true;
                  }
                }
              });
          } else {
            this.header = "Ошибка";
            this.message = "Введенные пароли не совпадают";
            this.closable = true;
            this.showModal = true;
          }
        }
      }
    },
    closeModalForm() {
      this.showModal = false;
      this.message = null;
      this.header = null;
      this.closable = false;
    },
  },

  beforeDestroy() {
    this.changePasswordValues.oldPassword = null;
    this.changePasswordValues.newPassword = null;
    this.changePasswordValues.newPasswordRepeat = null;
    this.header = null;
    this.message = null;
    this.showModal = false;
  },
};
</script>

<style>
.profile > .row {
  border-top: 1px solid #eceeef;
  padding-bottom: 15px;
  padding-top: 15px;
}

.profile > .row:first-child {
  padding-top: 0;
  border-top: 0;
}

.profile > .row:last-child {
  padding-bottom: 0;
}
</style>
