<template>
  <div
    class="error-wrapper white-box"
    style="
      display: -webkit-flex;
      -webkit-align-items: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    "
  >
    <div class="white-box_body">
      <p class="w-100" style="text-align: center; align-items: center">
        {{ message }}
      </p>
      <button-element round @click="refresh">
        {{ $constants.actions.refresh }}
      </button-element>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../button/button";

export default {
  name: "ErrorComponent",
  components: {
    ButtonElement,
  },
  props: {
    message: {
      type: String,
      default: null,
    },
  },
  methods: {
    refresh: function () {
      this.$emit("refresh");
    },
  },
};
</script>

<style></style>
