<script setup>
import DeviceList from "../devices/DeviceList.vue";
import * as api from "../../api/courier_api";
import DeviceSettings from "../devices/DeviceSettings.vue";
import LoaderComponent from "../common/LoaderComponent.vue";
import ModalWindow from "../modal/main.vue";
import Message from "../message/main";
import ErrorComponent from "../common/ErrorComponent.vue";

export default {
  name: "DevicesPage",
  data() {
    return {
      modal: {
        show: false,
        closable: false,
        headerText: "",
        message: "",
        processing: false,
        controlButtons: []
      },
      sessionToTerminate: null,

      devicesData: {
        currentDevice: undefined,
        otherSessions: [],
        error: {
          message: undefined
        },
        isLoading: false,
      },

      settingsData: {
        content: undefined,
        isLoading: false,
        error: {
          message: undefined
        }
      },
    }
  },
  components: {
    DeviceList,
    DeviceSettings,
    LoaderComponent,
    ModalWindow,
    Message,
    ErrorComponent,
  },
  methods: {
    fetchDevices: function () {
      this.devicesData.isLoading = true
      this.devicesData.error = {
        message: undefined
      }

      api.sessions.getInfo().then(data => {
        data = data.data;

        this.devicesData.currentDevice = {
          id: data.current.id,
          deviceName: data.current.device_name,
          appVersion: data.current.app_version,
          appType: parseInt(data.current.app_type),
          date: data.current.last_used_at,
          ip: data.current.ip,
          terminatable: false,
        }

        this.devicesData.otherSessions = [];

        data.active.forEach(item => {
          this.devicesData.otherSessions.push({
            id: item.id,
            deviceName: item.device_name,
            appVersion: item.app_version,
            appType: parseInt(item.app_type),
            date: item.last_used_at,
            ip: item.ip,
            terminatable: true,
          })
        })

        this.devicesData.isLoading = false
      }).catch((error) => {
        this.devicesData.isLoading = false
        this.devicesData.error = {
          message: 'Ошибка'
        }
      })
    },
    terminateSession: function () {
      const id = this.sessionToTerminate;
      if (!id) return;

      this.modal.processing = true;
      api.sessions.terminateById(id)
        .then(data => {
          this.modal.show = false; 
          this.sessionToTerminate = null;
          this.fetchDevices();
          this.modal.processing = false;

          Message({
            message: "Сеанс успешно завершен",
            position: "right",
            showClose: true,
            type: 'success'
          });
        })
        .catch(error => {
          this.modal.processing = false;
          this.modal.show = false;

          const message = error.response?.data?.message || "Не удалось завершить сеанс. Попробуйте позже.";
          Message({
            message: message,
            type: "error",
            position: "right", 
            showClose: true
          });
          console.error('Error terminating session:', error);
        });
    },
    terminateAllSessions: function () {
      this.modal.processing = true;

      api.sessions.terminateAll()
        .then(data => {
          this.modal.show = false;
          this.fetchDevices();
          this.modal.processing = false;

          Message({
            message: "Сеансы успешно завершены", 
            position: "right",
            showClose: true,
            type: 'success'
          });
        })
        .catch(error => {
          this.modal.processing = false;
          this.modal.show = false;

          const message = error.response?.data?.message || "Не удалось завершить сеансы. Попробуйте позже.";
          Message({
            message: message,
            type: "error",
            position: "right",
            showClose: true
          });
          console.error('Error terminating all sessions:', error);
        });
    },
    fetchSettings: function() {
      this.settingsData.isLoading = true;
      this.settingsData.error = {
        message: undefined
      };

      api.sessions.settingsGetInfo().then(data => {
        console.log('Settings data:', data);
        this.settingsData.content = data.data.max_non_use_period;
        this.settingsData.isLoading = false;
      }).catch((error) => {
        console.error('Settings error:', error);
        this.settingsData.isLoading = false;
        this.settingsData.error = {
          message: 'Ошибка'
        };
      });
    },
    receiveEmit(id) {
      this.showTerminateModal(id)
    },
    showTerminateModal(id) {
      this.modal.headerText = 'Завершить сеанс'
      this.modal.message = 'Вы действительно хотите завершить сеанс?'
      this.modal.closable = true
      this.modal.processing = false
      this.sessionToTerminate = id

      this.modal.controlButtons = [
        {
          text: 'Отменить',
          emitEvent: 'close'
        },
        {
          text: 'Продолжить',
          emitEvent: 'terminateSession'
        }
      ];

      this.modal.show = true
    },
    showTerminateAllModal() {
      this.modal.headerText = 'Завершить все сеансы'
      this.modal.message = 'Вы действительно хотите завершить все сеансы, кроме текущего?'
      this.modal.processing = false

      this.modal.controlButtons = [
        {
          text: 'Отменить',
          emitEvent: 'close'
        },
        {
          text: 'Продолжить',
          emitEvent: 'terminateAllSessions'
        }
      ];

      this.modal.closable = true
      this.modal.show = true
    },
    closeModalForm() {
      if (this.modal.show) {
        this.modal.show = false;
      }
      this.modal.headerText = null;
      this.buttons = null;
      this.modal.closable = false;
      this.modal.message = null;
      this.modal.processing = false;
    },
    changeSessionTtl(value) {
      this.settingsData.isLoading = true;
      
      api.sessions.settingsUpdateExpiration(value)
        .then(data => {
          this.settingsData.isLoading = false;
          Message({
            message: "Настройка успешно изменена",
            position: "right",
            showClose: true,
            type: 'success'
          });
          
          this.fetchSettings();
        })
        .catch(error => {
          this.settingsData.isLoading = false;
          
          const message = error.response?.data?.message || "Не удалось изменить настройку. Попробуйте позже.";
          Message({
            message: message,
            type: "error",
            position: "right", 
            showClose: true
          });
          
          console.error('Error updating session settings:', error);
          
          this.fetchSettings();
        });
    }
  },
  mounted() {
    this.fetchDevices()
    this.fetchSettings()
  }
}
</script>

<template>
  <div class="white-box">
    <template v-if="this.modal.show">
      <modal-window v-if="this.modal.show" :closable="this.modal.closable" :show="this.modal.show"
        :show-mobile-apps-promo="false" :text="this.modal.message" :header-text="this.modal.headerText"
        :buttons="this.modal.controlButtons" @close="closeModalForm" @terminateSession="terminateSession"
        @terminateAllSessions="terminateAllSessions" :processing="this.modal.processing" />
    </template>

    <template v-if="devicesData.isLoading">
      <div class="white-box_header">Это устройство</div>
      <loader-component />
    </template>
    <template v-else>
      <template v-if="devicesData.error.message">
        <div class="white-box_header">Это устройство</div>
        <error-component :message="devicesData.error.message" @refresh="fetchDevices" />
      </template>
      <template v-else>
        <div>
          <div class="white-box_header">Это устройство</div>
          <div class="white-box_body">
            <DeviceList v-bind:devices="devicesData.currentDevice ?
              [devicesData.currentDevice] : []""/>
            <div>
              <template
                v-if="devicesData.otherSessions.length === 0">
      </template>
      <template v-else>
        <span class="link alert-link" style="color: red; cursor: pointer; padding: 0.5rem 1rem"
          @click="showTerminateAllModal">
          <img style="color: red" :src="require('@/assets/images/hand-stop.svg')" alt="" />
          Завершить все другие сеансы
        </span>
      </template>
  </div>
  </div>
  </div>
  <div>
    <div class="white-box_header">Активные сеансы</div>
    <div class="white-box_body">
      <template v-if="devicesData.otherSessions.length === 0">
        <div style="padding: 1rem 1rem">Нет активных сеансов</div>
      </template>
      <template v-else>
        <DeviceList :devices="devicesData.otherSessions" @terminate="receiveEmit" />
      </template>
    </div>
  </div>
</template>
</template>
<div class="white-box_header">Настройки</div>
<div class="white-box_body">
  <template v-if="settingsData.error.message">
    <error-component :message="settingsData.error.message" @refresh="fetchSettings" />
  </template>
  <template v-else>
    <DeviceSettings :is-loading="settingsData.isLoading" :max-non-use-period="settingsData.content"
      @change-session-ttl="changeSessionTtl" />
  </template>
</div>
</div>
</template>

<style scoped></style>
