<template>
  <div>
    <div id="tariff-wrapper">
      <template v-if="showModal">
        <modal-window
          v-if="showModal"
          :closable="closable"
          :show="showModal"
          :header-text="header"
          :text="message"
          :buttons="buttons"
          @setPlannedTariff="setPlannedTariff(selectedTariff.id)"
          @close="closeModalForm"
        />
      </template>
      <div
        class="white-box"
        v-if="availableTariffs && Object.keys(availableTariffs).length !== 0"
      >
        <div class="white-box_header">Доступные тарифы</div>
        <div class="white-box_body">
          <div class="row align-items-center justify-content-center">
            <template v-for="tariff in availableTariffs">
              <div class="col-md-12 col-lg-6 col-xl-4" :key="tariff.id">
                <tariff-card
                  :id="tariff.id"
                  :name="tariff.name"
                  :speed-night="parseInt(tariff.speed.night)"
                  :speed-day="parseInt(tariff.speed.day)"
                  :speed-unit="tariff.speed.unit"
                  :bonus-text="tariff.bonus"
                  :price="parseFloat(tariff.amount)"
                  show-change-tariff-button="true"
                  @changeTariff="changeTariff"
                  :credit-disabled="tariff.credit.disabled"
                  :credit-expired="tariff.credit.expired"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="white-box"
      v-if="otherTariffs && Object.keys(otherTariffs).length !== 0"
    >
      <div class="white-box_header">
        Остальные тарифы, представленные на сайте
        <a href="https://www.shadrinsk.net/tarifs.html" class="link"
          >shadrinsk.net</a
        >, доступны при наличии технической возможности.
      </div>

      <div class="white-box_body">
        <div class="row align-items-center justify-content-center">
          <template v-for="(tariff, key) in otherTariffs">
            <div class="col-md-12 col-lg-6 col-xl-4" :key="key">
              <tariff-card
                :id="tariff.id"
                :name="tariff.name"
                :speed-night="parseInt(tariff.speed.night)"
                :speed-day="parseInt(tariff.speed.day)"
                :speed-unit="tariff.speed.unit"
                :bonus-text="tariff.bonus"
                :price="parseFloat(tariff.amount)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import store from "../../vuex/store.js";

// import MoonLoader from "vue-spinner/src/ScaleLoader.vue";
import ModalWindow from "../modal/main";

import { default as Message } from "../message/main.js";
import TariffCard from "./card.vue";

export default {
  name: "AvailableTariff",

  data: function () {
    return {
      message: null,
      header: null,
      showModal: false,
      closable: false,
      selectedTariff: {},
      controlButtons: [
        {
          text: "Отменить",
          emitEvent: "close",
        },
        {
          text: "Продолжить",
          emitEvent: "setPlannedTariff",
        },
      ],
      buttons: null,
    };
  },

  components: {
    // MoonLoader,
    TariffCard,
    ModalWindow,
  },

  computed: {
    nextMonth() {
      return this.$store.getters["account/getNextMonth"];
    },
    availableTariffs() {
      return this.$store.getters["tariffs/getAvailableTariffs"];
    },
    otherTariffs() {
      return this.$store.getters["tariffs/getOtherTariffs"];
    },
  },

  methods: {
    setPlannedTariff(tariffId) {
      console.log(tariffId);
      if (this.selectedTariff) {
        this.selectedTariff = {};
      }

      this.message = null;
      this.closable = false;
      this.buttons = null;
      this.header = null;

      if (tariffId) {
        this.$store
          .dispatch("tariffs/setPlannedTariff", tariffId)
          .then(() => {
            this.$emit("update");
            this.closeModalForm();
            Message({
              message: "Тариф был успешно запланирован.",
              type: "success",
              position: "right",
              showClose: true,
            });
          })
          .catch((error) => {
            /*this.message =
              "Не удалось перейти на новый тариф, попробуйте позже.";*/
            this.closeModalForm();
            Message({
              message:
                "Не удалось перейти на новый тариф, попробуйте позже." + error,
              type: "error",
              position: "right",
              showClose: true,
            });
          });
      }
    },

    changeTariff(tariffId) {
      this.selectedTariff = this.availableTariffs[tariffId];
      this.closable = true;
      this.header = "Смена тарифа";
      this.message = `Вы действительно хотите перейти на тариф "${this.selectedTariff.name}"? Переход будет осуществлён с ${this.nextMonth}.`;
      this.buttons = this.controlButtons;
      this.showModal = true;
    },

    closeModalForm() {
      this.message = null;
      this.header = null;
      this.buttons = null;
      this.closable = false;
      this.showModal = false;
      this.selectedTariff = {};
    },
  },

  mounted() {
    //console.log(this.availableTariffs);
  },
};
</script>
