import moment from "moment";
import * as api from "../../api/courier_api";
// import Vue from "vue";

const tariffs = {
  namespaced: true,

  state: {
    tariffs: {
      meta: null,
      current: null,
      planned: null,
      available: null,
      other: null,
    },
  },

  mutations: {
    SET_CURRENT_TARIFF(state, payload) {
      console.log(payload);
      let current = payload.current;
      if (current) {
        state.tariffs.current = current;
      }
    },

    SET_AVAILABLE_TARIFFS(state, payload) {
      let available = payload.available;
      if (available) {
        state.tariffs.available = [];
        available.forEach(function (tariff) {
          if (
            state.tariffs.current.id !== tariff.id &&
            (state.tariffs.planned === null ||
              state.tariffs.planned.id !== tariff.id)
          ) {
            state.tariffs.available[tariff.id] = Object.assign(
              {},
              state.tariffs.available[tariff.id],
              tariff,
            );
          }
        });
        state.tariffs.available = Object.assign({}, state.tariffs.available);
      }
    },

    SET_PLANNED_TARIFF(state, payload) {
      if (payload === null) {
        state.tariffs.planned = null;
      } else {
        state.tariffs.planned = payload.planned;
      }
    },

    SET_OTHER_TARIFFS(state, payload) {
      state.tariffs.other = payload.other;
    },

    SET_META(state, payload) {
      if (payload.meta) {
        if (payload.meta.planned) {
          payload.meta.planned = moment.unix(payload.meta.planned);
        }
        state.tariffs.meta = Object.assign({}, payload.meta);
      }
    },

    CLEAR_STATE(state) {
      state.tariffs = {
        meta: null,
        current: null,
        planned: null,
        available: null,
        other: null,
      };
    },
  },

  actions: {
    fetchTariffs: ({ commit }) => {
      return api.account.getTariffs().then((data) => {
        //console.log(data);
        let tariffs = data.data;

        if (tariffs.current) {
          let current = tariffs.current;
          commit("SET_CURRENT_TARIFF", { current });
        }

        if (tariffs.planned) {
          let planned = tariffs.planned;
          commit("SET_PLANNED_TARIFF", { planned });
        } else {
          commit("SET_PLANNED_TARIFF", null);
        }

        if (tariffs.available) {
          let available = tariffs.available;
          commit("SET_AVAILABLE_TARIFFS", { available });
        }

        if (tariffs.other) {
          let other = tariffs.other;
          commit("SET_OTHER_TARIFFS", { other });
        }

        if (data.meta) {
          let meta = data.meta;
          commit("SET_META", { meta });
        }

        return data;
      });
    },

    fetchCurrentTariff: ({ commit }) => {
      return api.account.getCurrentTariff().then((data) => {
        commit("SET_CURRENT_TARIFF", { data });
        return data;
      });
    },

    fetchAvailableTariffs: ({ commit }) => {
      return api.account.getAvailableTariffs().then((data) => {
        commit("SET_AVAILABLE_TARIFFS", { data });
        return data;
      });
    },

    fetchPlannedTariff: ({ commit }) => {
      return api.account.getPlannedTariff().then((data) => {
        commit("SET_PLANNED_TARIFF", { data });
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    setPlannedTariff: ({ commit }, tariffName) => {
      return api.account.setPlannedTariff(tariffName).then((data) => {
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    deletePlannedTariff: ({ commit }) => {
      return api.account.deletePlannedTariff().then((data) => {
        return data;
      });
    },
  },

  getters: {
    getTariffs: (state) => {
      return state.tariffs;
    },
    getMeta: (state) => {
      return state.tariffs.meta;
    },
    getCurrentTariff: (state) => {
      return state.tariffs.current;
    },
    getPlannedTariff: (state) => {
      if (state.tariffs.planned) {
        return state.tariffs.planned;
      } else {
        return null;
      }
    },
    getAvailableTariffs: (state) => {
      return state.tariffs.available;
    },
    getOtherTariffs: (state) => {
      return state.tariffs.other;
    },
  },
};

export default tariffs;
