import Vue from "vue";
import Vuex from "vuex";

import * as apiFpst from "../api/fpst_api.js";

Vue.use(Vuex);

const cams = {
  namespaced: true,

  state: {
    cams: {},
    camCategories: null,
  },

  mutations: {
    SET_CAMS(state, payload) {
      if (state.camCategories) {
        state.cams = payload.data;
      } else {
        state.cams = payload.data;
      }
    },

    SET_CAM_HLS_URL(state, payload) {
      payload.camera.HlsUrl = payload.data.URL;
    },

    SET_CAM_CATEGORIES(state, payload) {
      state.camCategories = payload.data;
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchCams: ({ dispatch, commit }) => {
      return apiFpst.cams.get().then((data) => {
        console.log(data);
        commit("SET_CAMS", { data });
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    fetchPreview: ({ dispatch, state }) => {
      var i = 1;
      for (const item of state.cams) {
        if (i === state.cams.length) {
          return apiFpst.cams.getPreviewImage(item.CameraID).then((data) => {
            item.PreviewUrl = data.URL;
          });
        } else {
          apiFpst.cams.getPreviewImage(item.CameraID).then((data) => {
            item.PreviewUrl = data.URL;
          });
        }
        i++;
      }
    },

    // eslint-disable-next-line no-unused-vars
    fetchHLS: ({ dispatch, commit }, camera) => {
      return apiFpst.cams.getHLS(camera.CameraID).then((data) => {
        commit("SET_CAM_HLS_URL", { data, camera });
        return data.URL;
      });
    },

    // eslint-disable-next-line no-unused-vars
    stopTranslation: ({ dispatch }, translationUrl) => {
      return apiFpst.cams.stopTranslation(translationUrl).then((data) => {
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    fetchCameraCategories: ({ dispatch, commit }) => {
      return apiFpst.cams.getCameraCategories().then((data) => {
        console.log(data);
        commit("SET_CAM_CATEGORIES", { data });
        return data;
      });
    },
  },

  getters: {
    getCams: (state) => {
      return state.cams;
    },

    getCategories: (state) => {
      return state.camCategories;
    },
  },
};

export default new Vuex.Store({
  modules: {
    cams,
  },
});
