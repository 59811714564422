const login = {
  prompt_account: "Логин или номер счёта",
  prompt_password: "Пароль",
  help_header: "Справка",
  help_text:
    'Для входа в приложение используйте пару логин и пароль или номер лицевого счета и пароль. Эти реквизиты для входа вы можете найти в договоре на оказание услуг связи ООО "Курьер плюс".',
  error_login_and_password_required: "Логин и пароль обязательны к заполнению.",
  error_wrong_login_or_password: "Неверный логин/номер счета или пароль",
};

const accountInfo = {
  full_name: "ФИО",
  login: "Логин",
  account: "Номер счёта",
  email: "Почта",
};

const accountStatus = {
  paid: "Интернет оплачен до ",
  promised: "Обещанный платёж до ",
  not_paid: "Интернет не оплачен",
  suspended: "Услуги приостановлены",
  inet_not_available: "Интернет недоступен до погашения задолженности",
};

const network = {
  ip_address: "IP-адрес",
  gateway: "Основной шлюз",
  mask: "Маска сети",
  dns1: "Предпочитаемый DNS",
  dns2: "Альтернативный DNS",
};

const tariffs = {
  other_aviable_tariffs:
    'Остальные тарифы, представленные на сайте <a href="http://shadrinsk.net" class="link">shadrinsk.net</a>, доступны при наличии технической возможности.',
  promised: "Обещанный платёж",
  tariff: "Тариф",
  planned_tariff: "Запланированный тариф",
  abon: "Абонентская плата",
  abon2: "₽/мес",
  speed: "Скорость",
  speed_day: "Скорость днём",
  speed_night: "Скорость ночью",
};

const payments = {
  pay_action: "Пополнить счет",
  promised: "Обещанный платеж",
  promisedInfo: [
    "Обещанный  платеж может быть предоставлен только 1 раз в месяц.",
    "При поступлении обещанного платежа с Вашего лицевого счета спишется абонентская плата за текущий расчетный месяц.",
    "По истечении срока он вычитается с вашего лицевого счета.",
    "Сумма обещанного платежа определяется размером задолженности с учетом абонентской платы за текущий месяц.",
    "Если после окончания срока действия обещанного платежа ваш баланс будет отрицательным, доступ к сети Интернет будет ограничен, до погашения задолженности.",
    "Доступ к сети Интернет после получения обещанного платежа будет предоставлен в течение 1 часа.",
  ],
};

const errors = {
  server_unavailable: "Сервер недоступен, попробуйте позже.",
  unexpected: "Ошибка подключения к серверу",
  promisedErrors: {
    default: "Не удалось получить обещанный платеж",
  },
};

const mobileAppPromo = {
  promised:
    'Обещанный платеж, который всегда под рукой! Приложения "Курьер плюс" для мобильных устройств.',
  payment:
    'Оплата услуг, которая всегда под рукой! Приложения "Курьер плюс" для мобильных устройств.',
};

const actions = {
  cancel: "Отменить",
  continue: "Продолжить",
  refresh: "Обновить",
};

const messages = {
  no_results: "Пока нет сообщений",
  more_info: "Подробнее",
};

const detalization = {
  no_results: "Нет ни одного пополнения/списания",
};

const chat = {
  no_results: "Пока нет сообщений",
};

const text = {
  courierPlus: "Курьер плюс",
};

export {
  login,
  accountStatus,
  network,
  tariffs,
  payments,
  errors,
  actions,
  accountInfo,
  text,
  messages,
  detalization,
  chat,
  mobileAppPromo,
};
