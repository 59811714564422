<template>
  <cams-component />
</template>

<script>
import CamsComponent from "../common/CamsComponent.vue";

export default {
  components: { CamsComponent },
  name: "CamsPage",
};
</script>
