<template>
  <chat-component />
</template>

<script>
import ChatComponent from "../common/ChatComponent.vue";

export default {
  components: { ChatComponent },
  name: "ChatPage",
};
</script>
