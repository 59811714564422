<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-md-12">
        <detalization-component />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import DetalizationComponent from "../common/DetalizationComponent.vue";
// import HeadComponent from "../common/HeadComponent.vue";

export default {
  name: "PagePayments",
  components: {
    DetalizationComponent,
    // HeadComponent
  },
  data: function () {
    return {
      isLoading: false,
      message: null,
    };
  },
  computed: {},

  methods: {},

  mounted() {},
};
</script>
