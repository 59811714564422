<template>
  <div class="apps-wrapper white-box">
    <div class="white-box_header">Приложения</div>
    <div class="white-box_body">
      <div class="row">
        <div class="col-12 col-md-6">
          Мобильные приложения позволяют:
          <ul>
            <li>пополнить лицевой счет;</li>
            <li>подключить услугу "Обещанный платеж";</li>
            <li>проверить баланс лицевого счета;</li>
            <li>перейти на новый тариф;</li>
            <li>просматривать детализацию счета;</li>
            <li>общаться со службой технической поддержки в чате;</li>
            <li>
              получать уведомления о новых предложениях компании, технических
              работах и др.
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="embed-responsive embed-responsive-21by9">
            <a href="https://vk.com/courierplus?w=wall-29159952_5131">
              <img
                class="embed-responsive-item"
                :src="require('@/assets/images/mobile-apps.jpg')"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 offset-0 col-md-6 offset-md-4">
          <a
            href="https://play.google.com/store/apps/details?id=net.shadrinsk.courierplus&hl=ru"
          >
            <img
              :src="require('@/assets/images/googleplay.jpg')"
              width="206"
              height="74"
              alt=""
            />
          </a>
          <a
            href="https://itunes.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80-%D0%BF%D0%BB%D1%8E%D1%81/id1312778211?mt=8"
          >
            <img
              :src="require('@/assets/images/appstore.jpg')"
              width="206"
              height="74"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Apps",
};
</script>
