var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-1 icon-container"},[(this.type == 1)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_cashbox.png')}})]:(
        _vm.type == 3 ||
        this.type == 4 ||
        this.type == 5 ||
        this.type == 10 ||
        this.type == 36 ||
        this.type == 52
      )?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_terminal.png')}})]:(this.type == 9)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_gift.png')}})]:(this.type == 38)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_cashbox.png')}})]:(this.type == 39)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_sber.png')}})]:(this.type == 43)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_ring.png')}})]:(this.type == 71)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_card.png')}})]:[(this.amount < 0)?[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_down.png')}})]:[_c('img',{attrs:{"height":"55px","width":"55px","src":require('@/assets/images/payments_up.png')}})]]],2),_c('div',{staticClass:"col-12 col-lg-9 detail"},[_c('div',{staticClass:"amount",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(this.amount)+" "),_c('i',{staticClass:"fa fa-rub",attrs:{"aria-hidden":"true"}})]),_c('div',[_vm._v(_vm._s(this.detail))])]),_c('div',{staticClass:"col-12 col-lg-2 timestamp"},[_vm._v(" "+_vm._s(_vm._f("formatDay")(this.createdDate))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }