<template>
  <div id="pay-wrapper">
    <template v-if="isLoading">
      <loader-component />
    </template>

    <template v-else-if="isSuccess">
      <div class="white-box">
        <div class="white-box_header">Оплата</div>

        <div class="white-box_body">
          <div
            class="auth-apps-links"
            style="background-color: #ffffff; padding-top: 0px"
          >
            <div class="alert alert-warning">
              <div class="row">
                <div class="col-12">
                  <div>
                    {{ this.$constants.mobileAppPromo.payment }}
                  </div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=net.shadrinsk.courierplus&amp;hl=ru"
                      target="_blank"
                    >
                      <img
                        :src="require('@/assets/images/googleplay.jpg')"
                        width="150"
                        height="50"
                        alt="android"
                      />
                    </a>
                    <a
                      href="https://itunes.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80-%D0%BF%D0%BB%D1%8E%D1%81/id1312778211?mt=8"
                      target="_blank"
                    >
                      <img
                        :src="require('@/assets/images/appstore.jpg')"
                        width="150"
                        height="50"
                        alt="ios"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form name="TinkoffPayForm">
            <div class="alert alert-info">
              Ваш номер счёта: {{ this.account.account }}
            </div>

            <div v-if="this.messages.length">
              <div
                :class="['alert', 'alert-' + item.style]"
                v-for="(item, key) in this.messages"
                :key="key"
              >
                <div v-if="item.header">
                  {{ item.header }}
                </div>
                <div v-html="item.text"></div>
              </div>
            </div>

            <div class="form-group">
              <label for="account" class="control-label"
                >Номер счёта для пополнения</label
              >
              <input
                id="account"
                class="form-control ym-disable-keys"
                type="text"
                placeholder="Номер счета"
                name="name"
                :disabled="vtbPayIsLoading"
                @change="orderIdChanged()"
                v-model="accountForPayment"
              />
            </div>

            <div class="form-group">
              <label for="name" class="control-label">ФИО плательщика</label>
              <input
                id="name"
                class="form-control ym-disable-keys"
                type="text"
                placeholder="ФИО плательщика"
                name="name"
                :disabled="vtbPayIsLoading"
                v-model="name"
              />
            </div>
            <div class="form-group">
              <label for="amount" class="control-label">
                Cумма пополнения счёта
              </label>
              <input
                id="amount"
                class="form-control ym-disable-keys"
                type="text"
                placeholder="500"
                name="amount"
                :disabled="vtbPayIsLoading"
                v-model="amount"
                required
              />
            </div>
            <div class="form-group">
              <label for="email" class="control-label"
                >E-mail для квитанции</label
              >
              <input
                id="email"
                class="form-control ym-disable-keys"
                type="text"
                placeholder="E-mail"
                name="email"
                :disabled="vtbPayIsLoading"
                v-model="email"
              />
            </div>
            <div class="row">
              <div class="col-12 d-flex">
                <input
                  class="c-button c-button--default is-round mr-3"
                  type="submit"
                  :disabled="vtbPayIsLoading"
                  @click.prevent="createOrder($event)"
                  value="Оплатить"
                />
                <button
                  class="c-button is-round d-flex align-items-center"
                  type="button"
                  @click.prevent="handleVtbPayButtonClick($event)"
                  :disabled="vtbPayIsLoading"
                  style="
                    background-color: #0663ef;
                    color: white;
                    border: none;
                    transition: background-color 0.3s;
                  "
                  :style="{
                    backgroundColor: '#0663ef',
                    opacity: vtbPayIsLoading ? '0.6' : '1',
                  }"
                  onmouseover="this.style.backgroundColor='#1a75ff'"
                  onmouseout="this.style.backgroundColor='#0663EF'"
                >
                  Оплатить через
                  <img
                    class="ml-1"
                    :src="require('@/assets/images/vtb_pay.svg')"
                    width="48"
                    height="19"
                    alt="vtb"
                    style="filter: brightness(0) invert(1)"
                  />
                  <span
                    v-if="vtbPayIsLoading"
                    class="spinner-border spinner-border-sm ml-2"
                  ></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>

    <error-component
      v-else-if="!isSuccess"
      :message="errorMessage"
      @refresh="fetchAccountInfo()"
    />

    <modal-window
      v-if="showModal"
      :closable="closable"
      :show="showModal"
      :header-text="header"
      :text="message"
      :buttons="buttons"
      @closeModalForm="closeModalForm"
      @close="closeModalForm"
    />
  </div>
</template>

<script>
import * as config from "../../config";
import * as api from "../../api/courier_api";
import { mapState } from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
// import HeadComponent from "../common/HeadComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";
import ModalWindow from "../modal/main";
import Message from "../message/main";

export default {
  name: "PagePay",

  components: {
    LoaderComponent,
    // HeadComponent,
    ErrorComponent,
    ModalWindow,
  },

  computed: {
    terminalKey: function () {
      return config.payments.terminalKey;
    },
    abonPayment() {
      return this.$store.getters["account/abonPayment"];
    },
    ...mapState({
      account: (state) => state.account.accountInfo,
    }),
  },

  data: function () {
    return {
      showModal: false,
      header: null,
      closable: true,
      buttons: null,
      errorMessage: null,
      orderId: {
        computedOrderId: null,
        get() {
          return this.computedOrderId;
        },
        set(value) {
          this.computedOrderId = value;
        },
      },
      messages: [],
      isLoading: false,
      isSuccess: true,
      vtbPayIsLoading: false,
      message: null,
      accountForPayment: {
        computedAccount: null,
        get() {
          return this.computedAccount;
        },
        set(value) {
          this.computedAccount = value;
        },
      },
      email: {
        computedEmail: null,
        get() {
          return this.computedEmail;
        },
        set(value) {
          this.computedEmail = value;
        },
      },
      amount: {
        computedAmount: null,
        get() {
          return this.computedAmount;
        },
        set(value) {
          this.computedAmount = value;
        },
      },
      name: {
        computedName: null,
        get() {
          return this.computedName;
        },
        set(value) {
          this.computedName = value;
        },
      },
    };
  },

  methods: {
    getMessages() {
      api.account.getPaymentMessages().then((data) => {
        this.messages = data;
      });
    },
    getRecommendedPay() {
      api.account.getRecommendedPaymentAmount().then((data) => {
        this.amount = data.amount / 100 ?? 0;
      });
    },
    createOrder() {
      if (
        this.accountForPayment.length === 0 ||
        !this.accountForPayment.trim()
      ) {
        this.message = "Введите номер счёта";
        this.header = "Ошибка";
        this.showModal = true;
      } else {
        this.isLoading = true;

        if (this.accountForPayment === this.account.account) {
          api.account
            .createOrder()
            .then((data) => {
              this.orderId = data.id;
              this.isLoading = false;
              this.payTinkoff();
            })
            .catch((error) => {
              this.isLoading = false;
              //this.isSuccess = false;
              if (error.response) {
                this.message = this.$constants.errors.unexpected;
              } else {
                this.message = this.$constants.errors.unexpected;
              }
              this.header = "Ошибка";
              this.showModal = true;
            });
        } else {
          api.account
            .createOrderByAccountNumber(this.accountForPayment)
            .then((data) => {
              this.orderId = data.id;
              this.payTinkoff();
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response) {
                switch (error.response.data.code) {
                  case 50:
                    this.message = "Введите номер счёта";
                    break;
                  case 51:
                    this.message = "Данного номера счёта не существует";
                    break;
                  default:
                    this.message = this.$constants.errors.unexpected;
                }
              } else {
                this.message = this.$constants.errors.unexpected;
              }
              this.header = "Ошибка";
              this.showModal = true;
            });
        }
      }
    },

    payTinkoff: function () {
      let payInfo = {
        terminalkey: {
          value: String(this.terminalKey),
        },
        order: {
          value: this.orderId,
        },
        description: {
          value: "Пополнение счета № " + this.accountForPayment,
        },
        email: {
          value: String(this.email),
        },
        amount: {
          value: String(this.amount),
        },
        frame: {
          value: String(false),
        },
        language: {
          value: "ru",
        },
      };
      window.pay(payInfo);
    },

    handleVtbPayButtonClick() {
      const amountInRubles = parseFloat(this.amount);

      if (!amountInRubles || isNaN(amountInRubles)) {
        Message({
          message: "Пожалуйста, введите корректную сумму",
          type: "warning",
          position: "right",
          showClose: true,
        });
        return;
      }

      if (amountInRubles <= 0) {
        Message({
          message: "Сумма должна быть больше нуля",
          type: "warning",
          position: "right",
          showClose: true,
        });
        return;
      }

      const amountInKopeks = Math.round(amountInRubles * 100);
      this.vtbPayIsLoading = true;
      api.account
        .getPaymentLink("vtb_pay", amountInKopeks)
        .then((data) => {
          let link = data.link;
          if (link) {
            window.location.href = link;
          } else {
            console.error("Payment link is missing");
          }
        })
        .catch((error) => {
          Message({
            message: "Ошибка при загрузке страницы оплаты ВТБ",
            type: "error",
            position: "right",
            showClose: true,
          });
        })
        .finally(() => {
          this.vtbPayIsLoading = false;
        });
    },

    closeModalForm() {
      this.showModal = false;
      this.message = null;
      this.header = null;
      this.closable = true;
    },

    fetchAccountInfo: function () {
      this.$store
        .dispatch("account/fetchAccountInfo", ["tariffs", "services", "credit"])
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
          this.accountForPayment = this.account.account;
          this.email = this.account.email;
          this.name = this.account.name;
          this.getRecommendedPay();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.isSuccess = false;
          this.errorMessage = this.$constants.errors.unexpected;
        });
    },

    orderIdChanged: function () {
      console.log(this.orderId.get());
      console.log(this.account.account);
      if (this.accountForPayment === this.account.account) {
        console.log(this.recommendedPay);
        this.amount = this.recommendedPay;
      } else {
        this.amount = 0;
      }
    },
  },
  beforeMount() {},

  mounted() {
    this.isLoading = true;
    this.getMessages();
    this.fetchAccountInfo();
    this.closeModalForm();
  },
};
</script>
