import * as api from "../../api/courier_api.js";
import * as constants from "../../constants/strings";
import moment from "moment";
import * as Centrifuge from "./../../assets/js/centrifuge";
// import Vue from "vue";

moment.locale("ru");

const account = {
  namespaced: true,

  state: {
    accountInfo: {
      account: null,
      login: null,
      amount: null,
      disabled: null,
      suspended: null,
      name: null,
      email: null,
      loyalty_points: null,
    },

    accountStats: {
      last: {},
      download: null,
      upload: null,
    },

    credit: {},

    services: {},

    network: {},

    centrifuge: null,

    badgesChat: 0,
    badgesMessages: 0,
    internetSettings: null,
  },

  mutations: {
    SET_ACCOUNT_STATS(state, payload) {
      let stats = payload.data.data;
      let currentMonth = moment().month();
      state.accountStats.upload = [];
      state.accountStats.download = [];
      stats.forEach((item) => {
        item.upload = parseFloat(item.upload).toFixed(2);
        item.download = parseFloat(item.download).toFixed(2);
        if (item.month - 1 === currentMonth) {
          console.log(item.month);
          state.accountStats.last = item;
        }
        state.accountStats.upload[item.month] = item.upload;
        state.accountStats.download[item.month] = item.download;
      });
      state.accountStats.upload = Object.assign({}, state.accountStats.upload);
      state.accountStats.download = Object.assign(
        {},
        state.accountStats.download,
      );
    },

    SET_BADGES(state, payload) {
      state.badgesChat = payload.data.chats;
      state.badgesMessages = payload.data.messages;
    },

    SET_BADGES_CHAT(state, count) {
      state.badgesChat = count;
    },

    SET_BADGES_MESSAGES(state, count) {
      state.badgesMessages = count;
    },

    SET_ACCOUNT_INFO(state, payload) {
      let data = payload.data;
      state.accountInfo.account = data.account;
      state.accountInfo.login = data.login;
      state.accountInfo.amount = data.amount;
      state.accountInfo.disabled = data.disabled;
      state.accountInfo.suspended = data.suspended;
      state.accountInfo.name = data.name;
      state.accountInfo.email = data.email;
      state.accountInfo.loyalty_points = data.loyalty_points;

      if (data.services) {
        state.services = data.services;
      }

      if (data.credit) {
        if (data.credit.data) {
          state.credit = data.credit.data;
        }
      }
    },
    SET_SERVICES(state, payload) {
      state.services = payload.data;
    },
    SET_NETWORK(state, payload) {
      state.network = payload.data;
    },
    SET_CENTRIFUGE(state, payload) {
      const params = payload.data;

      state.centrifuge = new Centrifuge({
        url: params.url,
        user: params.id.toString(),
        timestamp: params.time.toString(),
        token: params.token,
      });
    },
    SET_INTERNET_SETTINGS(state, payload) {
      state.internetSettings = payload.data;
    },
    CLEAR_STATE(state) {
      state.accountInfo = {
        account: null,
        login: null,
        amount: null,
        disabled: null,
        suspended: null,
        name: null,
        email: null,
        loyalty_points: null,
      };
      state.accountStats = {
        last: {},
        download: null,
        upload: null,
      };
      state.credit = {};
      state.services = {};
      state.network = {};
      state.centrifuge = null;
      state.badgesChat = 0;
      state.badgesMessages = 0;
      state.internetSettings = null;
    },
  },

  actions: {
    fetchAccountStats: ({ commit }, year) => {
      if (!year) {
        year = moment().year();
      }
      return api.account.getAccountStats(year).then((data) => {
        commit("SET_ACCOUNT_STATS", { data });
        return data;
      });
    },

    fetchInternetSettings: ({ commit }) => {
      return api.account.getInternetSettings().then((data) => {
        commit("SET_INTERNET_SETTINGS", { data });
        return data;
      });
    },

    changeInternetSettings: ({ commit }, url) => {
      return api.account.changeInternetSettings(url).then((data) => {
        return data;
      });
    },

    fetchBadges: ({ commit }) => {
      return api.account.getBadges().then((data) => {
        commit("SET_BADGES", { data });
        return true;
      });
    },

    fetchAccountInfo: ({ commit }, includes) => {
      console.log("fetchAccountInfo");
      return api.account.getInfo(includes).then((data) => {
        commit("SET_ACCOUNT_INFO", { data });
        if (data.tariffs) {
          let tariffs = data.tariffs.data;
          let current = tariffs.current;
          let planned = tariffs.planned;
          let available = tariffs.available;
          let other = tariffs.other;
          let meta = data.tariffs.meta;

          if (planned) {
            commit("tariffs/SET_PLANNED_TARIFF", { planned }, { root: true });
          }
          if (current) {
            commit("tariffs/SET_CURRENT_TARIFF", { current }, { root: true });
          }
          if (available) {
            commit(
              "tariffs/SET_AVAILABLE_TARIFFS",
              { available },
              { root: true },
            );
          }
          if (other) {
            commit("tariffs/SET_OTHER_TARIFFS", { other }, { root: true });
          }
          if (meta) {
            commit("tariffs/SET_META", { meta }, { root: true });
          }
        }
        return data;
      });
    },

    fetchServices: ({ commit }) => {
      return api.account.getServices().then((data) => {
        commit("SET_SERVICES", { data });
        return data;
      });
    },

    fetchNetwork: ({ commit }) => {
      return api.account.getNetwork().then((data) => {
        commit("SET_NETWORK", { data });
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    postCredit: ({ commit }) => {
      return api.account.postCredit().then((data) => {
        return data;
      });
    },
  },

  getters: {
    getAccountInfo: (state) => {
      return state.accountInfo;
    },
    getStatUpload: (state) => {
      return state.accountStats.upload;
    },

    getStatDownload: (state) => {
      return state.accountStats.download;
    },
    getLastStatsUpload: (state) => {
      return state.accountStats.last.upload || 0;
    },
    getLastStatsDownload: (state) => {
      return state.accountStats.last.download || 0;
    },
    getFpstSessionId: (state) => {
      console.log("getFpstSessionId: " + state.fpstSession);
      return state.fpstSession;
    },

    getFpstEmbedClientUrl: (state) => {
      console.log("getFpstSessionId: " + state.embedClientUrl);
      return state.embedClientUrl;
    },

    getBadgesChat: (state) => {
      console.log("badgesChat: " + state.badgesChat);
      return state.badgesChat;
    },

    getBadgesMessages: (state) => {
      console.log("badgesMessages: " + state.badgesMessages);
      return state.badgesMessages;
    },

    getCentrifuge: (state) => {
      return state.centrifuge;
    },

    abonPayment: (state, getters, rootState, rootGetters) => {
      let servicesCount = state.services.data.length;
      if (servicesCount > 1) {
        let totalAmount = 0;
        // let currentTariffAmount = 0;
        state.services.data.forEach(function (service) {
          if (!service.disabled) {
            if (!service.tariff) {
              // currentTariffAmount = parseFloat(service.amount);
            }
            totalAmount += parseFloat(service.amount);
          }
        });
        return parseFloat(totalAmount);
      } else {
        let current = rootGetters["tariffs/getCurrentTariff"];
        return parseFloat(current.amount);
      }
    },
    plannedAbonPayment: (state, getters, rootState, rootGetters) => {
      let current = rootGetters["tariffs/getCurrentTariff"];
      const inet_sf = parseFloat(current.amount);
      let planned = rootGetters["tariffs/getPlannedTariff"];
      let subscription_fee = parseFloat(getters.abonPayment);
      if (planned && Object.keys(planned).length !== 0) {
        let plannedAmount = parseFloat(planned.amount);
        subscription_fee = parseFloat(
          subscription_fee - inet_sf + plannedAmount,
        );
      }
      return subscription_fee;
    },
    getServices: (state) => {
      if (state.services.data) {
        Array.from(state.services.data).forEach(function (service) {
          if (service.name == "Интернет" && service.disabled) {
            const index = state.services.data.indexOf(service);
            state.services.data.splice(index, 1);
          }
        });
        return state.services;
      }
    },

    getServicesCount: (state) => {
      if (state.services.data) {
        return Object.keys(state.services.data).length;
      }
      return 0;
    },

    getNextMonth: () => {
      return moment().add(1, "month").startOf("month").format("D MMMM YYYY");
    },

    accountStatus: (state) => {
      if (state.accountInfo) {
        if (state.accountInfo.suspended) {
          return constants.accountStatus.suspended;
        } else if (Object.keys(state.credit).length !== 0) {
          let date = moment(state.credit.expired * 1000).format("D MMMM YYYY");
          return constants.accountStatus.promised + date;
        } else if (
          !state.accountInfo.disabled &&
          state.accountInfo.amount < 0
        ) {
          return constants.accountStatus.inet_not_available;
        } else if (state.accountInfo.disabled) {
          return constants.accountStatus.not_paid;
        } else {
          let now = moment()
            .add(1, "month")
            .startOf("month")
            .format("D MMMM YYYY");
          return constants.accountStatus.paid + now;
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    recommendedPay: (state, getters, rootState, rootGetters) => {
      let currentBalance = parseFloat(state.accountInfo.amount);
      let subscription_fee = parseFloat(getters.plannedAbonPayment);

      var recommendedPaymentAmountFloat = 0;
      if (!state.accountInfo.suspended && currentBalance < subscription_fee) {
        recommendedPaymentAmountFloat = parseFloat(
          subscription_fee - currentBalance,
        );
        let recommendedPaymentAmount = recommendedPaymentAmountFloat;
        if (recommendedPaymentAmountFloat - recommendedPaymentAmount > 0) {
          recommendedPaymentAmount += 1;
        }
        if (currentBalance < 0 && !state.accountInfo.disabled) {
          recommendedPaymentAmount = -currentBalance;
        }
        return recommendedPaymentAmount;
      } else {
        return 0;
      }
    },
  },
};

export default account;
