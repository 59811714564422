<template>
  <div class="chat-wrapper">
    <div v-if="isSuccess">
      <div class="container" style="background-color: #ffffff">
        <div
          class="message-container"
          v-bind:class="{ 'message-container-small': isImagesAttached }"
        >
          <infinite-loading
            @infinite="loadMessages"
            ref="infiniteLoading"
            direction="top"
            :distance="distance"
          >
            <template
              v-if="
                chatRoomMessages !== null &&
                Object.keys(chatRoomMessages).length > 0
              "
            >
              <span slot="no-more" />
              <span slot="no-results" />
            </template>
            <template v-else>
              <span slot="no-more"></span>
              <span slot="no-results"
                >{{ this.$constants.chat.no_results }}
              </span>
            </template>
          </infinite-loading>
          <ul class="chat-list">
            <template v-for="(item, key) in chatRoomMessagesAllKeys">
              <chat-group
                :key="key"
                :timestamp="item"
                :messages="chatRoomMessages[item]"
              />
            </template>
          </ul>
        </div>
      </div>
      <div class="chat-inputs container" style="background-color: #f4f7f9">
        <div class="img-wrap" v-if="images">
          <div
            style="
              margin-right: 16px;
              display: inline-block;
              background-color: #fff;
            "
            v-for="(image, key) in images"
            :key="key"
          >
            <div
              class="img-preview"
              :style="'background-image: url(' + image + ');'"
              @click="removeImage(image)"
            />
          </div>
        </div>

        <form class="chat-input">
          <textarea
            v-model="message"
            :placeholder="placeholder"
            class="chat-input--text"
            style="width: calc(100% - 100px)"
            @keydown="handleKey"
          />

          <div class="chat-input--buttons flex-fill">
            <div class="chat-input--button">
              <label htmlFor="file-input">
                <button class="chat-input--file-icon-wrapper" type="button">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    class="chat-input--file-icon"
                    x="0px"
                    y="0px"
                    width="30px"
                    height="30px"
                    viewBox="0 0 37.393 37.393"
                    enableBackground="new 0 0 37.393 37.393"
                  >
                    <path
                      d="M20.807 10.22l-2.030-2.029-10.15 10.148c-1.682 1.681-1.682 4.408 0 6.089s4.408 1.681 6.090 0l12.18-12.178c2.804-2.802 2.804-7.346 0-10.148-2.802-2.803-7.347-2.803-10.149 0l-12.788 12.787c-0.009 0.009-0.019 0.018-0.027 0.026-3.909 3.909-3.909 10.245 0 14.153 3.908 3.908 10.246 3.908 14.156 0 0.009-0.009 0.016-0.018 0.026-0.027l0.001 0.001 8.729-8.728-2.031-2.029-8.729 8.727c-0.009 0.008-0.018 0.018-0.026 0.026-2.784 2.783-7.312 2.783-10.096 0-2.783-2.783-2.783-7.31 0-10.093 0.010-0.009 0.019-0.018 0.028-0.026l-0.001-0.002 12.79-12.786c1.678-1.679 4.411-1.679 6.090 0s1.678 4.411 0 6.089l-12.18 12.178c-0.56 0.56-1.47 0.56-2.030 0-0.559-0.559-0.559-1.47 0-2.029l10.15-10.149z"
                    />
                  </svg>
                </button>
                <input type="file" id="file-input" @change="addFile" />
              </label>
            </div>
            <div class="chat-input--button">
              <button
                @click.prevent="sendMessage"
                class="chat-input--send-icon-wrapper"
              >
                <svg
                  version="1.1"
                  class="chat-input--send-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30px"
                  height="30px"
                  viewBox="0 0 37.393 37.393"
                  enableBackground="new 0 0 37.393 37.393"
                >
                  <g id="Layer_2">
                    <path
                      d="M36.511,17.594L2.371,2.932c-0.374-0.161-0.81-0.079-1.1,0.21C0.982,3.43,0.896,3.865,1.055,4.241l5.613,13.263
                          L2.082,32.295c-0.115,0.372-0.004,0.777,0.285,1.038c0.188,0.169,0.427,0.258,0.67,0.258c0.132,0,0.266-0.026,0.392-0.08
                          l33.079-14.078c0.368-0.157,0.607-0.519,0.608-0.919S36.879,17.752,36.511,17.594z M4.632,30.825L8.469,18.45h8.061
                          c0.552,0,1-0.448,1-1s-0.448-1-1-1H8.395L3.866,5.751l29.706,12.757L4.632,30.825z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container" v-else-if="!isSuccess">
      <error-component
        v-on:refresh="fetchChatRoomMessages"
        :message="errorMessage"
      >
      </error-component>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import * as api from "../../api/courier_api";
import { mapGetters } from "vuex";
import ErrorComponent from "./ErrorComponent.vue";
import * as Centrifuge from "./../../assets/js/centrifuge";

import ChatGroup from "../chat/group";

export default {
  name: "chat-component",

  components: {
    InfiniteLoading,
    ErrorComponent,
    ChatGroup,
  },

  data: function () {
    return {
      placeholder: "Напишите сообщение...",
      count: 0,
      isImagesAttached: false,
      isSuccess: true,
      distance: 300,
      container: null,
      errorMessage: null,
      message: "",
      files: [],
      images: null,
      messages: [],
      currentDate: null,
      centrifuge: null,
      checkNewMessagesCurrentPage: 0,
      perPage: 15,
    };
  },

  computed: {
    ...mapGetters({
      chatRoomMessagesTotalPages: "chatroom/allChatroomMessagesTotalPages",
      chatRoomMessages: "chatroom/allChatRoomMessages",
      //chatRoomMaxLength: "chatroom/getChatroomMaxLength"
    }),

    getChatroomMessageLastId: {
      get() {
        return this.$store.getters["chatroom/getChatroomMessageLastId"];
      },
    },

    chatRoomMessagesCurrentPage: {
      get() {
        return this.$store.getters["chatroom/allChatroomMessagesCurrentPage"];
      },
      set(value) {
        this.$store.commit("chatroom/SET_CHATROOM_CURRENT_PAGE", value);
      },
    },

    chatRoomMessagesAllKeys: {
      get() {
        return this.$store.getters["chatroom/getChatRoomMessagesAllKeys"];
      },
    },

    badgeChat: {
      get() {
        return this.$store.getters["account/getBadgesChat"];
      },
      set(value) {
        this.$store.commit("account/SET_BADGES_CHAT", value);
      },
    },

    /*chatTime(message) {
      message.createdTime = this.$options.filters.formatTime(
          this.message.created
      );
      return message.createdTime;
    }*/
  },

  mounted: function () {
    this.connectToCentrifuge();
    this.checkNewMessages();
    this.container = document.getElementsByClassName("message-container")[0];
    this.scrollToMessage();
  },

  beforeDestroy: function () {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
      this.centrifuge = null;
    }
  },

  methods: {
    /*isSupport: function(message) {
      return message.support_id != null;
    },

    isNotSupport: function(message) {
      return !this.isSupport(message);
    },*/

    handleKey(event) {
      if (event.keyCode === 13 && event.shiftKey) {
        this.addNewLine();

        console.log(this.message);
      }

      if (event.keyCode === 13 && !event.shiftKey) {
        this.sendMessage();
        event.preventDefault();
      }
    },

    async checkNewMessages() {
      let allMessagesRead = false;
      let i = 0;

      while (!allMessagesRead) {
        let messagesRead = true;

        await this.$store
          .dispatch("chatroom/fetchChatRoomMessagesReturnValues", i)
          .then((data) => {
            const newMessages = data.data;
            this.checkNewMessagesCurrentPage = i;

            newMessages.forEach((el) => {
              if (!el.read && el.support_id != null) {
                messagesRead = false;
                el.read = true;

                if (this.badgeChat > 0) {
                  this.badgeChat--;
                }

                this.addMessage(el);
              }
            });
          });
        allMessagesRead = messagesRead;
        i++;
      }
    },

    /*formatTime: function(value) {
      if (value) {
        value.createdTime = this.$options.filters.formatTime(value.created);
        return value.createdTime;
      }
    },*/

    addFile: function (e) {
      var file = e.target.files || e.dataTransfer.files;

      if (file.length) {
        if (this.files == null) {
          this.files = [];
        }
        this.files.push.apply(this.files, file);
        this.createImage(file[0]);
      }
    },

    removeImage(image) {
      let index = this.images.indexOf(image);

      if (index > -1) {
        this.files.splice(index, 1);
        this.images.splice(index, 1);
      }

      if (this.images.length === 0) {
        this.images = null;
        this.isImagesAttached = false;
      }
    },

    createImage(file) {
      if (!this.images) {
        this.images = [];
      }

      const reader = new FileReader();
      var vm = this;
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        vm.images.push(e.target.result);
        vm.isImagesAttached = true;
        console.log(vm.images);
      };
    },

    addNewLine: function () {
      console.log(`${this.message}\n`);
      this.message = `${this.message}\n`;
    },

    sendMessage: function () {
      if (
        (this.message == null || this.message.trim(" ") === "") &&
        this.files == null
      ) {
        return;
      }

      api.account
        .postChatroomMessage(this.message, this.files)
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });

      this.message = null;
      this.images = null;
      this.files = null;
      this.isImagesAttached = false;
    },

    addMessage: function (message) {
      if (message != null) {
        console.log("addMessage");
        message.created = this.$moment.unix(message.created);

        message.createdDay = this.$options.filters.formatDay(message.created);

        if (!this.chatRoomMessagesAllKeys.includes(message.createdDay)) {
          this.chatRoomMessagesAllKeys.push(message.createdDay);
          console.log(
            "!this.chatRoomMessagesAllKeys.includes(message.createdDay)",
          );
        }

        var id = message.id;
        var messageObject = {};

        this.$set(messageObject, id, message);

        var reactiveObject = Object.assign({}, messageObject);

        this.chatRoomMessages[message.createdDay] = Object.assign(
          {},
          this.chatRoomMessages[message.createdDay],
          reactiveObject,
        );
        console.log(this.chatRoomMessages[message.createdDay]);
        this.scrollToMessage();
      }
    },

    connectToCentrifuge: function () {
      this.centrifuge = null;

      api.account.postChatroom().then((data) => {
        this.centrifuge = new Centrifuge({
          url: data.url,
          user: data.id.toString(),
          timestamp: data.time.toString(),
          token: data.token,
        });

        this.centrifuge.subscribe("users#" + data.id, (message) => {
          console.log(message.data);
          this.addMessage(message.data);
        });

        this.centrifuge.on("disconnect", function (context) {
          console.log("disconnect | " + context);
        });

        this.centrifuge.on("error", function (error) {
          console.log(error);
        });

        this.centrifuge.on("connect", function (context) {
          console.log("connect | " + context);
        });

        this.centrifuge.connect();
      });
    },

    scrollToMessage: function (message_id) {
      var id = message_id || 0;
      console.log(`scrolled to ${id} message`);
      console.log(id);
      this.$nextTick(function () {
        if (id === 0) {
          console.log(this.container.scrollTop);
          return (this.container.scrollTop = this.container.scrollHeight);
        }
        var el = document
          .getElementById("message" + message_id)
          .getBoundingClientRect();
        const pageYOffset = window.pageYOffset;
        return (this.container.scrollTop = el.top - pageYOffset - 200);
      });
    },

    loadMessages($state) {
      var id = this.getChatroomMessageLastId;

      this.$store
        .dispatch(
          "chatroom/fetchChatRoomMessages",
          this.chatRoomMessagesCurrentPage + 1,
        )
        .then(() => {
          this.scrollToMessage(id);
          $state.loaded();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              $state.complete();
            } else {
              this.isSuccess = false;
              this.errorMessage = this.$constants.errors.unexpected;
              console.log(error);
            }
          } else {
            this.isSuccess = false;
            this.errorMessage = this.$constants.errors.unexpected;
            console.log(error);
          }
        });
    },

    fetchChatRoomMessages: function () {
      let id = 0;

      if (
        this.chatRoomMessages.length > 0 &&
        this.getChatroomMessageLastId > 0
      ) {
        id = this.getChatroomMessageLastId;
      }

      this.$store
        .dispatch(
          "chatroom/fetchChatRoomMessages",
          this.chatRoomMessagesCurrentPage + 1,
        )
        .then(() => {
          this.isSuccess = true;
          this.scrollToMessage(id);
        })
        .catch((error) => {
          this.isSuccess = false;
          this.errorMessage = this.$constants.errors.unexpected;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.chat-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.chat-input--file-icon {
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none;
}

.chat-input--file-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.chat-input--send-icon-wrapper {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.chat-input--send-icon-wrapper:focus {
  outline: none;
}

.chat-input--send-icon {
  cursor: pointer;
  align-self: center;
  outline: none;
}

.chat-input--send-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.chat-input--button:hover path {
  fill: rgba(86, 88, 103, 1);
}

.chat-input {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  margin: 0;
  position: relative;
  bottom: 0;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition:
    background-color 0.2s ease,
    box-shadow 0.2s ease;
}

.chat-input--text {
  background-color: #ffffff;
  width: 85%;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  height: 50px;
  max-height: 80px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-input--text:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.chat-input--buttons {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  width: 80px;
  -ms-align-items: center;
  align-items: center;
}

.chat-input--button {
  width: 60px;
  height: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.chat-input.active {
  background-color: white;
  box-shadow: 0 -5px 20px 0 rgba(150, 165, 190, 0.2);
}

.chat-input--button label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.chat-input--button button {
  cursor: pointer;
}

.chat-input--button label:hover path {
  fill: rgba(86, 88, 103, 1);
}

.chat-input--button input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

.file-container {
  background-color: #f4f7f9;
  border-top-left-radius: 10px;
  padding: 0;
  color: #565867;
}

.delete-file-message {
  font-style: normal;
  float: right;
  cursor: pointer;
  color: #c8cad0;
}

.delete-file-message:hover {
  color: #5d5e6d;
}

.icon-file-message {
  margin-right: 5px;
}
</style>
