import moment from "moment";
import * as api from "../../api/courier_api";

const payments = {
  namespaced: "true",
  state: {
    payments: {
      latestPlusPayment: {},
      all: [],
      allKeys: [],
      allTotalPages: null,
      allCurrentPage: 0,
    },
  },
  mutations: {
    SET_LAST_PLUS_PAYMENT(state, payload) {
      let latestPlusPayment = payload.data;
      latestPlusPayment.created = moment.unix(latestPlusPayment.created);
      state.payments.latestPlusPayment = latestPlusPayment;
    },
    ADD_PAYMENTS(state, payload) {
      const payments = payload.data.data;

      state.payments.allCurrentPage = payload.data.meta.pagination.current_page;

      state.payments.allTotalPages = payload.data.meta.pagination.total_pages;

      payments.forEach(function (payment) {
        payment.created = moment.unix(payment.created);
        let paymentId = payment.id;
        state.payments.all[paymentId] = payment;

        if (
          state.payments.allKeys !== null &&
          !state.payments.allKeys.includes(paymentId)
        ) {
          state.payments.allKeys.push(paymentId);
        }
      });

      state.payments.all = Object.assign({}, state.payments.all);
    },
    CLEAR_STATE(state) {
      state.payments = {
        latestPlusPayment: {},
        all: [],
        allKeys: [],
        allTotalPages: null,
        allCurrentPage: 0,
      };
    },
  },
  actions: {
    fetchPayments: ({ commit }, page) => {
      if (!page) {
        page = 0;
      }
      return api.account.getPayments(page).then((data) => {
        commit("ADD_PAYMENTS", { data });
        return data;
      });
    },
    fetchLatestPlusPayment: ({ commit }) => {
      return api.account.getLatestPlusPayment().then((data) => {
        commit("SET_LAST_PLUS_PAYMENT", { data });
        return data;
      });
    },
    clear: ({ commit }) => {
      commit("CLEAR_STATE");
    },
  },
  getters: {
    latestPlusPayment: (state) => {
      return state.payments.latestPlusPayment;
    },
    allPaymentsTotalPages: (state) => {
      return state.payments.allTotalPages;
    },
    allPaymentsCurrentPage: (state) => state.payments.allCurrentPage,
    payments: (state) => {
      return state.payments.all;
    },
    allKeys: (state) => {
      return state.payments.allKeys;
    },
  },
};

export default payments;
