<template>
  <div class="internet-settings">
    <div v-for="value in settings" v-bind:key="value.id">
      <div class="row">
        <div class="col-8 col-md-6">
          <a
            class="link"
            data-toggle="collapse"
            :href="'#collapseMessage' + value.id"
            role="button"
            aria-expanded="false"
            :aria-controls="'collapseMessage' + value.id"
            v-if="null !== value.description"
          >
            {{ value.name }}
          </a>
          <template v-else>
            {{ value.name }}
          </template>
        </div>
        <div class="col-4 col-md-6" style="align-content: flex-end">
          <toggle-button
            :sync="true"
            :value="Boolean(value.state.value)"
            @change="change(value.state.change_url)"
          />
        </div>
      </div>
      <div
        :id="'collapseMessage' + value.id"
        class="row collapse"
        v-if="null !== value.description"
      >
        <div class="col-12" v-html="value.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "NetworkComponent",
  components: { ToggleButton },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    change(url) {
      this.$emit("change", url);
    },
  },
};
</script>

<style>
.internet-settings > div {
  border-top: 1px solid #eceeef;
  padding-bottom: 15px;
  padding-top: 15px;
}

.internet-settings > div:first-child {
  padding-top: 0;
  border-top: 0;
}

.internet-settings > div:last-child {
  padding-bottom: 0;
}
</style>
