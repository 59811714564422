<script setup>
export default {
  name: "DeviceListItem",
  props: {
    device: {
      id: String,
      deviceName: String,
      appVersion: String,
      appType: Number,
      date: String,
      ip: String,
      terminatable: Boolean,
    },
  },
  methods: {
    parseDate: function (date) {
      date = this.$options.filters.parseDate(date);
      date = this.$options.filters.formatSessionDate(date);
      return date;
    },
  },
};
</script>

<template>
  <div
    :class="
      this.device.terminatable ? 'list-item list-item-active' : 'list-item'
    "
    @click="$emit('terminate', device.id)"
  >
    <div style="display: flex; align-items: center; justify-content: center">
      <template v-if="this.device.appType === 0">
        <img
          class="icon icon-device"
          :src="require('@/assets/images/devices/web.svg')"
          alt=""
        />
      </template>
      <template v-else-if="this.device.appType === 1">
        <img
          class="icon icon-device"
          :src="require('@/assets/images/devices/android.svg')"
          alt=""
        />
      </template>
      <template v-else-if="this.device.appType === 2">
        <img
          class="icon icon-device"
          :src="require('@/assets/images/devices/apple.svg')"
          alt=""
        />
      </template>
      <template v-else>
        <img
          class="icon icon-device"
          :src="require('@/assets/images/devices/web.svg')"
          alt=""
        />
      </template>
    </div>
    <div>
      <div style="display: flex" class="line">
        <div class="color-black">
          {{ this.device.deviceName }}
          {{
            this.device.appType == 0
              ? "web"
              : this.device.appType == 1
                ? "android"
                : "apple"
          }}
        </div>
        <div class="color-muted date">
          {{ parseDate(this.device.date) }}
        </div>
      </div>
      <div class="color-black line">
        {{ this.device.appVersion }}
      </div>
      <div class="color-muted line">
        {{ this.device.ip }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-item-active:hover {
  border-radius: 10px;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
  cursor: pointer;
}

.list-item .icon-device {
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: 2rem;
  margin-inline-end: 1.5rem !important;
}

.icon-device-android {
  background-image: require("@/assets/images/messages_unreaded.png");
}

.icon-device-ios {
  background-image: url("https://web.telegram.org/a/ios.7b9f7d5b4c4f0b0e6f1a.svg");
}

.icon-device-browser {
  background-image: url("https://web.telegram.org/a/firefox.d61f7f1396d1867cc631.svg");
}

.list-item .date {
  margin-left: auto;
}

.list-item {
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 50px auto;
}

.list-item .line {
  flex: 1;
}

.color-black {
  color: black;
}

.color-muted {
  color: rgb(142, 142, 147);
}
</style>
