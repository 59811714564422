<template>
  <div class="messages-wrapper">
    <div v-if="isSuccess" class="white-box">
      <div class="white-box_header">Уведомления</div>
      <div class="white-box_body items notifications">
        <template v-for="key in messagesKeys">
          <div class="row" :key="key">
            <div class="col-12 col-lg-1 icon-container">
              <template v-if="!messages[key].readed">
                <img
                  height="55px"
                  width="55px"
                  :src="require('@/assets/images/messages_unreaded.png')"
                />
              </template>
              <template v-else>
                <img
                  height="55px"
                  width="55px"
                  :src="require('@/assets/images/messages_readed.png')"
                />
              </template>
            </div>
            <div class="col-12 col-lg-9">
              <a
                class="link"
                data-toggle="collapse"
                :href="'#collapseMessage' + messages[key].id"
                role="button"
                aria-expanded="false"
                :aria-controls="'collapseMessage' + messages[key].id"
                @click="readMessage(messages[key])"
              >
                {{ messages[key].title }}
              </a>
              <div
                :id="'collapseMessage' + messages[key].id"
                v-if="messages[key].message"
                class="collapse"
              >
                <div class="row message">
                  <div class="col-12 col-lg-2" v-if="messages[key].image">
                    <div class="embed-responsive embed-responsive-16by9">
                      <img
                        class="embed-responsive-item"
                        :src="messages[key].image"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-10">
                    <div class="msg white-space-pre">
                      {{ messages[key].message }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 offset-lg-4 more">
                    <a
                      v-if="messages[key].link"
                      :href="messages[key].link"
                      class="c-button c-button--default is-round"
                      >Подробнее</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-2 timestamp">
              {{ parseDate(messages[key]) }}
            </div>
          </div>
        </template>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        force-use-infinite-wrapper="true"
      >
        <template v-if="messages !== null && Object.keys(messages).length > 0">
          <span slot="no-more" />
          <span slot="no-results" />
        </template>
        <template v-else>
          <span slot="no-more" />
          <span slot="no-results">
            {{ this.$constants.messages.no_results }}
          </span>
        </template>
      </infinite-loading>
    </div>
    <error-component
      @refresh="updateMessages(true)"
      v-else-if="!isSuccess"
      :message="message"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import LoaderComponent from "./LoaderComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";
import InfiniteLoading from "vue-infinite-loading";
// import ButtonElement from "../button/button";

export default {
  name: "MessagesComponent",

  data: function () {
    return {
      isSuccess: true,
      message: null,
    };
  },

  components: {
    // LoaderComponent,
    ErrorComponent,
    InfiniteLoading,
    // ButtonElement
  },

  computed: {
    badgeMessages: {
      get() {
        return this.$store.getters["account/getBadgesMessages"];
      },
      set(value) {
        this.$store.commit("account/SET_BADGES_MESSAGES", value);
      },
    },

    ...mapGetters({
      messagesTotalPages: "messages/allMessagesTotalPages",
      messagesCurrentPage: "messages/allMessagesCurrentPage",
    }),

    messages() {
      let messages = this.$store.getters["messages/messages"];
      console.log(messages);

      return this.$store.getters["messages/messages"];
    },

    messagesKeys() {
      return this.$store.getters["messages/allKeys"];
    },
  },

  methods: {
    infiniteHandler($state) {
      if (this.messagesCurrentPage <= this.messagesTotalPages) {
        this.$store
          .dispatch("messages/fetchMessages", this.messagesCurrentPage + 1)
          .then(() => {
            $state.loaded();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                $state.complete();
              } else {
                this.isSuccess = false;
                this.message = this.$constants.errors.unexpected;
                console.log(error);
              }
            } else {
              this.isSuccess = false;
              this.message = this.$constants.errors.unexpected;
              console.log(error);
            }
          });
      }
    },

    readMessage(message) {
      if (!message.readed) {
        this.$store.dispatch("messages/readMessage", message.id).then(() => {
          this.$store.commit(
            "messages/UPDATE_MESSAGE_READED_STATUS",
            message.id,
          );
          this.badgeMessages--;
        });
      }
    },

    updateMessages(forced = false) {
      if (forced) {
        this.fetchAccountMessages();
      } else {
        if (!this.messages || Object.keys(this.messages).length === 0) {
          this.fetchAccountMessages();
        }
      }
    },

    fetchAccountMessages() {
      this.$store
        .dispatch("messages/fetchMessages", this.messagesCurrentPage + 1)
        .then(() => {
          console.log("success");
          this.isSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          this.isSuccess = false;
          if (error.response) {
            this.message = this.$constants.errors.unexpected;
          } else {
            this.message = this.$constants.errors.unexpected;
          }
        });
    },

    parseDate: function (value) {
      if (value) {
        value.createdTime = this.$options.filters.parseDate(value.created);
        value.createdTime = this.$options.filters.formatDay(value.createdTime);
        return value.createdTime;
      }
    },
  },

  mounted() {},
};
</script>
