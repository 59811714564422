<template>
  <messages-component />
</template>

<script>
// import { mapState } from "vuex";
import MessagesComponent from "../common/MessagesComponent.vue";
// import HeadComponent from "../common/HeadComponent.vue";

export default {
  name: "PageInfo",
  data: function () {
    return {};
  },

  components: {
    MessagesComponent,
    // HeadComponent
  },

  computed: {},

  methods: {},
};
</script>
